import { Box, Grid, Tab, Tabs, Tooltip } from "@mui/material";
import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { Route, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { Button, CloseButton, Input, Pagination, PaginationItem, PaginationLink, Table } from 'reactstrap';
import Loader from "./Loader";
import PaginatedItems from "./Paginate";
import YksCalculate from "./YksCalculate";
import biltes_logo from "../Assets/biltes_logo.jpeg";
import { AiFillHome } from "react-icons/ai";

function useQuery() {
    const { search } = useLocation();
  
    return useMemo(() => new URLSearchParams(search), [search]);
}

interface props {
  isLoading: boolean;
  
}

function Yks(props:props) {

    const [mobileTabs, setMobileTabs] = useState(0);
    const [isLoaded, setLoaded] = useState(false)
    const [tableLoading, setTableLoading] = useState(false)
    const [Schools, SetSchools] = useState([]);
    const [selectedSchools, setSelectedSchools] = useState([])
    const [PaginationData, setPagination] = useState({
        prev_index: 0,
        next_index: 2,
        current_index: 1,
        is_have_next: false,
        is_have_previous: false,
        last_page: 10 
    });

    const [state, setState] = useState({
        year: 2021,
        ogrenci_adi: "",
        ogrenci_dip_puani: "",
        tyt_data: {
            turkce: 0,
            mat: 0,
            fen: 0,
            sos: 0,

            ham_puan: 0,
            yerlestirme_puan: 0,
            ham_basari_sirasi: 0,
            yerlestirme_basari_sirasi: 0
        },

        ayt_data:{
            mat: 0,
            fizik: 0,
            kimya: 0,
            biyoloji: 0,
            edebiyat: 0,
            tarih_1:0,
            cografya_1: 0,
            tarih_2: 0,
            cografya_2: 0,
            felsefe: 0,
            din: 0,
            dil: 0,

            sayisal_ham_puan: 0,
            sayisal_yerlestirme_puan: 0,
            sayisal_ham_basari_sirasi: 0,
            sayisal_yerlestirme_sirasi: 0,

            sozel_ham_puan: 0,
            sozel_yerlestirme_puan: 0,
            sozel_ham_basari_sirasi: 0,
            sozel_yerlestirme_sirasi: 0,

            ea_ham_puan: 0,
            ea_yerlestirme_puan: 0,
            ea_ham_basari_sirasi: 0,
            ea_yerlestirme_sirasi: 0,

            dil_ham_puan: 0,
            dil_yerlestirme_puan: 0,
            dil_ham_basari_sirasi: 0,
            dil_yerlestirme_sirasi: 0
        }
    })
    
    

    const query = useQuery();
    const location = useLocation();

    useEffect(() => {
      setState({
        year: 2021,
        ogrenci_adi: "",
        ogrenci_dip_puani: "",
        tyt_data: {
            turkce: 0,
            mat: 0,
            fen: 0,
            sos: 0,

            ham_puan: 0,
            yerlestirme_puan: 0,
            ham_basari_sirasi: 0,
            yerlestirme_basari_sirasi: 0
        },

        ayt_data:{
            mat: 0,
            fizik: 0,
            kimya: 0,
            biyoloji: 0,
            edebiyat: 0,
            tarih_1:0,
            cografya_1: 0,
            tarih_2: 0,
            cografya_2: 0,
            felsefe: 0,
            din: 0,
            dil: 0,

            sayisal_ham_puan: 0,
            sayisal_yerlestirme_puan: 0,
            sayisal_ham_basari_sirasi: 0,
            sayisal_yerlestirme_sirasi: 0,

            sozel_ham_puan: 0,
            sozel_yerlestirme_puan: 0,
            sozel_ham_basari_sirasi: 0,
            sozel_yerlestirme_sirasi: 0,

            ea_ham_puan: 0,
            ea_yerlestirme_puan: 0,
            ea_ham_basari_sirasi: 0,
            ea_yerlestirme_sirasi: 0,

            dil_ham_puan: 0,
            dil_yerlestirme_puan: 0,
            dil_ham_basari_sirasi: 0,
            dil_yerlestirme_sirasi: 0
        }
    })
    }, [])
    
    useEffect(() => {
      if(location.pathname.includes("lgs")) return
        //37.230.104.39 // https://tercihrobotu.testprojesi.com // http://localhost:5000
        axios.get(`https://tercihrobotu.testprojesi.com/api/get-yks-pre?page=${query.get('page')}&on=${query.get('on')}&odp=${query.get('odp')}&tq1=${query.get('tq1')}&tq2=${query.get('tq2')}&tq3=${query.get('tq3')}&tq4=${query.get('tq4')}&thp=${query.get('thp')}&typ=${query.get('typ')}&thbs=${query.get('thbs')}&tybs=${query.get('tybs')}&aq1=${query.get('aq1')}&aq2=${query.get('aq2')}&aq3=${query.get('aq3')}&aq4=${query.get('aq4')}&aq5=${query.get('aq5')}&aq6=${query.get('aq6')}&aq7=${query.get('aq7')}&aq8=${query.get('aq8')}&aq9=${query.get('aq9')}&aq10=${query.get('aq10')}&aq11=${query.get('aq11')}&aq12=${query.get('aq12')}&ashp=${query.get('ashp')}&asyp=${query.get('asyp')}&ashbs=${query.get('ashbs')}&asybs=${query.get('asybs')}&asohp=${query.get('asohp')}&asoyp=${query.get('asoyp')}&asohbs=${query.get('asohbs')}&asoybs=${query.get('asoybs')}&aehp=${query.get('aehp')}&aeyp=${query.get('aeyp')}&aehbs=${query.get('aehbs')}&aeybs=${query.get('aeybs')}&adhp=${query.get('adhp')}&adyp=${query.get('adyp')}&adhbs=${query.get('adhbs')}&adybs=${query.get('adybs')}&pturu=${query.get('pturu')}`)
        .then((res) => {
        setState({...state, ogrenci_dip_puani:res.data.dip_puani, ogrenci_adi: res.data.ogrenci_adi, 
          tyt_data: 
          {...state.tyt_data, turkce: res.data.tyt.tyt_turkce, mat: res.data.tyt.tyt_mat, fen: res.data.tyt.tyt_fen, sos: res.data.tyt.tyt_sos, ham_puan: res.data.tyt.tyt_ham_puan,
            yerlestirme_puan: res.data.tyt.tyt_yerlestirme_puan,
            ham_basari_sirasi: res.data.tyt.tyt_ham_basari_sirasi,
            yerlestirme_basari_sirasi: res.data.tyt.tyt_yerlestirme_basari_sirasi
          }, 
          ayt_data:{
            ...state.ayt_data,
            mat: res.data.ayt.ayt_mat,
            fizik: res.data.ayt.ayt_fizik,
            kimya: res.data.ayt.ayt_kimya,
            biyoloji: res.data.ayt.ayt_biyoloji,
            edebiyat: res.data.ayt.ayt_edebiyat,
            felsefe: res.data.ayt.ayt_felsefe,
            din: res.data.ayt.ayt_din,
            dil: res.data.ayt.ayt_dil,
            tarih_1: res.data.ayt.ayt_tarih_1,
            tarih_2: res.data.ayt.ayt_tarih_2,
            cografya_1: res.data.ayt.ayt_cografya_1,
            cografya_2: res.data.ayt.ayt_cografya_2,
            
            sayisal_ham_puan: res.data.ayt.ayt_shp,
            sayisal_ham_basari_sirasi: res.data.ayt.ayt_shbs,
            sayisal_yerlestirme_puan: res.data.ayt.ayt_syp,
            sayisal_yerlestirme_sirasi: res.data.ayt.ayt_sybs,

            sozel_ham_basari_sirasi: res.data.ayt.ayt_sohbs,
            sozel_ham_puan: res.data.ayt.ayt_sohp,
            sozel_yerlestirme_puan: res.data.ayt.ayt_soyp,
            sozel_yerlestirme_sirasi: res.data.ayt.ayt_soybs,

            ea_ham_basari_sirasi: res.data.ayt.ayt_ehbs,
            ea_ham_puan: res.data.ayt.ayt_ehp,
            ea_yerlestirme_puan: res.data.ayt.ayt_eyp,
            ea_yerlestirme_sirasi: res.data.ayt.ayt_eybs,

            dil_ham_basari_sirasi: res.data.ayt.ayt_dhbs,
            dil_ham_puan: res.data.ayt.ayt_dhp,
            dil_yerlestirme_puan: res.data.ayt.ayt_dyp,
            dil_yerlestirme_sirasi: res.data.ayt.ayt_dybs,
          }
        })
       
        
        }).catch(() => {

        });
    }, [location.pathname])

    useEffect(() => {
      
      if(location.pathname.includes("lgs")) return
        setTableLoading(true)
        
        axios.get(`https://tercihrobotu.testprojesi.com/api/get-yks?on=${query.get('on')}&odp=${query.get('odp')}&tq1=${query.get('tq1')}&tq2=${query.get('tq2')}&tq3=${query.get('tq3')}&tq4=${query.get('tq4')}&thp=${query.get('thp')}&typ=${query.get('typ')}&aq1=${query.get('aq1')}&aq2=${query.get('aq2')}&aq3=${query.get('aq3')}&aq4=${query.get('aq4')}&aq5=${query.get('aq5')}&aq6=${query.get('aq6')}&aq7=${query.get('aq7')}&aq8=${query.get('aq8')}&aq9=${query.get('aq9')}&aq10=${query.get('aq10')}&aq11=${query.get('aq11')}&aq12=${query.get('aq12')}&ashp=${query.get('ashp')}&asyp=${query.get('asyp')}&asohp=${query.get('asohp')}&asoyp=${query.get('asoyp')}&aehp=${query.get('aehp')}&aeyp=${query.get('aeyp')}&adhp=${query.get('adhp')}&adyp=${query.get('adyp')}&pturu=${query.get('pturu')}`)
        .then((res) => {
          
        SetSchools(res.data.universiteler)
        setPagination(res.data.pagination)
        
        setState({...state, ogrenci_dip_puani:res.data.dip_puani, ogrenci_adi: res.data.ogrenci_adi, 
          tyt_data: 
          {...state.tyt_data, turkce: res.data.tyt.tyt_turkce, mat: res.data.tyt.tyt_mat, fen: res.data.tyt.tyt_fen, sos: res.data.tyt.tyt_sos, ham_puan: res.data.tyt.tyt_ham_puan,
            yerlestirme_puan: res.data.tyt.tyt_yerlestirme_puan,
            ham_basari_sirasi: res.data.tyt.tyt_ham_basari_sirasi,
            yerlestirme_basari_sirasi: res.data.tyt.tyt_yerlestirme_basari_sirasi
          }, 
          ayt_data:{
            ...state.ayt_data,
            mat: res.data.ayt.ayt_mat,
            fizik: res.data.ayt.ayt_fizik,
            kimya: res.data.ayt.ayt_kimya,
            biyoloji: res.data.ayt.ayt_biyoloji,
            edebiyat: res.data.ayt.ayt_edebiyat,
            felsefe: res.data.ayt.ayt_felsefe,
            din: res.data.ayt.ayt_din,
            dil: res.data.ayt.ayt_dil,
            tarih_1: res.data.ayt.ayt_tarih_1,
            tarih_2: res.data.ayt.ayt_tarih_2,
            cografya_1: res.data.ayt.ayt_cografya_1,
            cografya_2: res.data.ayt.ayt_cografya_2,
            
            sayisal_ham_puan: res.data.ayt.ayt_shp,
            sayisal_ham_basari_sirasi: res.data.ayt.ayt_shbs,
            sayisal_yerlestirme_puan: res.data.ayt.ayt_syp,
            sayisal_yerlestirme_sirasi: res.data.ayt.ayt_sybs,

            sozel_ham_basari_sirasi: res.data.ayt.ayt_sohbs,
            sozel_ham_puan: res.data.ayt.ayt_sohp,
            sozel_yerlestirme_puan: res.data.ayt.ayt_soyp,
            sozel_yerlestirme_sirasi: res.data.ayt.ayt_soybs,

            ea_ham_basari_sirasi: res.data.ayt.ayt_ehbs,
            ea_ham_puan: res.data.ayt.ayt_ehp,
            ea_yerlestirme_puan: res.data.ayt.ayt_eyp,
            ea_yerlestirme_sirasi: res.data.ayt.ayt_eybs,

            dil_ham_basari_sirasi: res.data.ayt.ayt_dhbs,
            dil_ham_puan: res.data.ayt.ayt_dhp,
            dil_yerlestirme_puan: res.data.ayt.ayt_dyp,
            dil_yerlestirme_sirasi: res.data.ayt.ayt_dybs,
          }
        })
        
        setTimeout(() => {
            setTableLoading(false)
            
        }, 1500);
        }).catch(() => {

        });
    }, [query.get('pturu')])

    function GetPage() {
      return `/yks/get-data/?on=${query.get('on')}&odp=${query.get('odp')}&tq1=${query.get('tq1')}&tq2=${query.get('tq2')}&tq3=${query.get('tq3')}&tq4=${query.get('tq4')}&thp=${query.get('thp')}&typ=${query.get('typ')}&aq1=${query.get('aq1')}&aq2=${query.get('aq2')}&aq3=${query.get('aq3')}&aq4=${query.get('aq4')}&aq5=${query.get('aq5')}&aq6=${query.get('aq6')}&aq7=${query.get('aq7')}&aq8=${query.get('aq8')}&aq9=${query.get('aq9')}&aq10=${query.get('aq10')}&aq11=${query.get('aq11')}&aq12=${query.get('aq12')}&ashp=${query.get('ashp')}&asyp=${query.get('asyp')}&asohp=${query.get('asohp')}&asoyp=${query.get('asoyp')}&aehp=${query.get('aehp')}&aeyp=${query.get('aeyp')}&adhp=${query.get('adhp')}&adyp=${query.get('adyp')}&pturu=${query.get('pturu')}`;        
    }

    function GetPuanTuru(tur:string) {
      if(tur === undefined) tur = "ea"
      return `/yks/get-data/?on=${query.get('on')}&odp=${query.get('odp')}&tq1=${query.get('tq1')}&tq2=${query.get('tq2')}&tq3=${query.get('tq3')}&tq4=${query.get('tq4')}&thp=${query.get('thp')}&typ=${query.get('typ')}&aq1=${query.get('aq1')}&aq2=${query.get('aq2')}&aq3=${query.get('aq3')}&aq4=${query.get('aq4')}&aq5=${query.get('aq5')}&aq6=${query.get('aq6')}&aq7=${query.get('aq7')}&aq8=${query.get('aq8')}&aq9=${query.get('aq9')}&aq10=${query.get('aq10')}&aq11=${query.get('aq11')}&aq12=${query.get('aq12')}&ashp=${query.get('ashp')}&asyp=${query.get('asyp')}&asohp=${query.get('asohp')}&asoyp=${query.get('asoyp')}&aehp=${query.get('aehp')}&aeyp=${query.get('aeyp')}&adhp=${query.get('adhp')}&adyp=${query.get('adyp')}&pturu=${tur}`  
    }

    function GetThreeDigitN(num:string | number) {
      
      var arr = []
      num = String(num)
      num = parseFloat(num).toFixed(2)
      

      if (Number(num) < 100){
        return 0
      }

      
      if(num.includes(",") || num.includes(".")) return num;

      if(num.length > 3){
        var curr_str = ""
        for (let index = 0; index < num.length; index++) {
          const digit = num[index];
          arr.push(digit);
        }

        for (let index = 0; index < 3; index++ ){
          const digit = arr[index]
          curr_str += digit;
        }

        curr_str += ",";

        arr = arr.splice(0, 3);

        for (let index = 0; index < 2; index++) {
          const digit = arr[index];
          curr_str += digit;
        }

        return curr_str;
      }else{
        return num;
      }
      
    }
    
    function numberWithCommas(x:any) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return(
        <Route path='/yks'>
          <div className="modal-get shadow-lg ">
              {props.isLoading && <Loader style={{ zIndex: 51 }} />}
              <div className='header'>
                  <Tooltip title="Ana Sayfa">
                  <Link  className="home-button"  to={"/yks"}>
                  <AiFillHome></AiFillHome>
                  </Link>
                  </Tooltip>
              
                  YKS TERCİH ROBOTU
              
              </div>

              <Route exact path={"/yks"}>
                <div className="main">
                  <Box width="100%" height="100%" display="flex" textAlign={"center"} flexDirection={"column"} alignItems="center">
                    <img src={biltes_logo} alt="" width={250} />
                    <div>
                    Tercih robotuna hoşgeldiniz, <b>başla</b> butonuna basarak hesaplama işlemine geçebilirsin.
                    </div>

                    <Link to={"/yks/hesapla"}>
                    <Button style={{ width: 200, backgroundColor: 'rgb(243, 212, 183)' }} className="mt-2">
                      Başla
                    </Button>
                    </Link>
                  </Box>
                </div>
              </Route>
        
              <Route path={"/yks/get"}>
              <div className='main'>
                  <div className='container'>
                    <div className='row align-items-center'>
                      <div className="col-md-1">
                        TYT
                      </div>
                      
                      <div className='col-md-1 d-flex flex-column align-items-center' >
                        <span className="netler-input">
                          Türkçe
                        </span>
                        <Input value={state.tyt_data.turkce} className='mt-2' style={{ width: 55, textAlign: "center" }} />
                      </div>
                      <div className='col-md-1 d-flex flex-column align-items-center' >
                        <span className="netler-input">
                          Matematik
                        </span>
                        <Input value={state.tyt_data.mat} className='mt-2' style={{ width: 55, textAlign: "center" }} />
                      </div>
                      <div className='col-md-1 d-flex flex-column align-items-center' >
                        <span className="netler-input">
                          Fen Bil.
                        </span>
                        <Input value={state.tyt_data.fen} className='mt-2' style={{ width: 55, textAlign: "center" }} />
                      </div>
                      <div className='col-md-1 d-flex flex-column align-items-center' >
                        <span className="netler-input">
                          Sos.
                        </span>
                        <Input value={state.tyt_data.sos} className='mt-2' style={{ width: 55, textAlign: "center" }} />
                      </div>
                      
                    </div>

                    <div className='row align-items-center mt-4'>
                      <div className="col-md-1">
                        AYT
                      </div>
                      
                      <div className='col-md-1 d-flex flex-column align-items-center' >
                        <span className="netler-input">
                          Edebiyat
                        </span>
                        <Input value={state.ayt_data.edebiyat} className='mt-2' style={{ width: 55, textAlign: "center" }} />
                      </div>
                      <div className='col-md-1 d-flex flex-column align-items-center' >
                        <span className="netler-input">
                          Matematik
                        </span>
                        <Input value={state.ayt_data.mat} className='mt-2' style={{ width: 55, textAlign: "center" }} />
                      </div>
                      <div className='col-md-1 d-flex flex-column align-items-center' >
                        <span className="netler-input">
                          Fizik
                        </span>
                        <Input value={state.ayt_data.fizik} className='mt-2' style={{ width: 55, textAlign: "center" }} />
                      </div>
                      <div className='col-md-1 d-flex flex-column align-items-center' >
                        <span className="netler-input">
                          Kimya
                        </span>
                        <Input value={state.ayt_data.kimya} className='mt-2' style={{ width: 55, textAlign: "center" }} />
                      </div>
                      <div className='col-md-1 d-flex flex-column align-items-center' >
                        <span className="netler-input">
                          Biyoloji
                        </span>
                        <Input value={state.ayt_data.biyoloji} className='mt-2' style={{ width: 55, textAlign: "center" }} />
                      </div>
                      <div className='col-md-1 d-flex flex-column align-items-center' >
                        <span className="netler-input">
                          Coğr. - 1
                        </span>
                        <Input value={state.ayt_data.cografya_1} className='mt-2' style={{ width: 55, textAlign: "center" }} />
                      </div>
                      <div className='col-md-1 d-flex flex-column align-items-center' >
                        <span className="netler-input">
                          Tarih - 1
                        </span>
                        <Input value={state.ayt_data.tarih_1} className='mt-2' style={{ width: 55, textAlign: "center" }} />
                      </div>
                      <div className='col-md-1 d-flex flex-column align-items-center' >
                        <span className="netler-input">
                          Coğr. - 2
                        </span>
                        <Input value={state.ayt_data.cografya_2} className='mt-2' style={{ width: 55, textAlign: "center" }} />
                      </div>
                      <div className='col-md-1 d-flex flex-column align-items-center' >
                        <span className="netler-input">
                          Tarih - 2
                        </span>
                        <Input value={state.ayt_data.tarih_2} className='mt-2' style={{ width: 55, textAlign: "center" }} />
                      </div>
                      <div className='col-md-1 d-flex flex-column align-items-center' >
                        <span className="netler-input">
                          Felsefe
                        </span>
                        <Input value={state.ayt_data.felsefe} className='mt-2' style={{ width: 55, textAlign: "center" }} />
                      </div>
                      <div className='col-md-1 d-flex flex-column align-items-center' >
                        <span className="netler-input">
                          Din
                        </span>
                        <Input value={state.ayt_data.din} className='mt-2' style={{ width: 55, textAlign: "center" }} />
                      </div>
                      
                      
                    </div>
                  </div>
                </div>
          
                <div className='explain mt-2 d-flex flex-column'>
                    <span>
                      Merhaba, <b>{state.ogrenci_adi}</b>
                    </span>

                    <span className="detail">
                        Diploma Notunuz: <b>{state.ogrenci_dip_puani}</b>
                    </span>
                    
                    <span className='detail'>
                    Puanlarınız YKS 2022 verilerine göre hesaplanmıştır. Netlerinize göre kazanabileceğiniz okulların listelerini görmek için lütfen tıklayın
                    </span>
          
                    <Link to={GetPuanTuru("tyt")}>
                    <Button style={{ width: 200, backgroundColor: 'rgb(243, 212, 183)' }} className='mt-4 sonuc-button'>
                        Sonuçları Getir
                    </Button>
                    </Link>
                </div>
              </Route>     

              <YksCalculate /> 

              <Route path={"/yks/get-data"}>
              <div className='main p-3 d-flex justify-content-center'>
                
                  <Grid sx={{ display: { xs: 'none', md: 'flex' } }} container justifyContent={"center"}>
                      <Grid item xl={3}>
                      <div className='row' style={{
                        borderWidth: 0,
                        borderRightWidth: 1,
                        borderStyle: "solid",
                        borderColor: "rgb(51, 51, 51, 0.1)"
                      }} >
                        <div className="col-md-12 d-flex flex-column align-items-center justify-content-center" >
                            <span className="netler-input">
                                <b>
                                Ham Puan
                                </b>
                            </span>
                            <div >
                            <Input value={GetThreeDigitN(state.tyt_data.ham_puan)} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                            </div>

                            <div className="netler-input">
                            {state.tyt_data.ham_puan == 0 ? "Sıralama Yok" : state.tyt_data.ham_basari_sirasi.toLocaleString()}
                            </div>
                        </div>

                        <div className="col-md-12 d-flex flex-column align-items-center justify-content-center mt-2" >
                            <span className="netler-input">
                                <b>
                                Yerleştirme Puanı
                                </b>
                            </span>
                            <div >
                            <Input value={GetThreeDigitN(state.tyt_data.yerlestirme_puan)} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                            </div>

                            <div className="netler-input">
                              {state.tyt_data.yerlestirme_puan == 0 ? "Sıralama Yok" : state.tyt_data.yerlestirme_basari_sirasi.toLocaleString()}
                            </div>
                        </div>

              
                        

                        <div className="d-flex justify-content-center align-items-center mt-2" style={{ width: "100%", fontSize: "0.75rem" }}>
                            TYT PUANLARINIZ
                        </div>
                      </div>
                    
                     
                      </Grid>

                      <Grid item xl={6} container justifyContent={"center"}>
                        <div className='d-flex flex-column justify-content-center align-items-start' style={{ marginLeft: 25 }}>
                          <div className="d-flex align-items-center" >
                              <span className="netler-input" style={{ width: 65}}>
                                
                              </span>
                              <div className="row" style={{ marginLeft: 25 }}>
                                  <div className='col-3 d-flex flex-column align-items-center justify-content-center' style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                                      Sayısal
                                  </div>
                                  
                                  <div className='col-3 d-flex flex-column align-items-center justify-content-center'style={{ marginLeft: 25, width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                                      Sözel
                                  </div>

                                  <div className='col-3 d-flex flex-column align-items-center justify-content-center' style={{ marginLeft: 25, width: 65, textAlign: "center", fontSize: "0.7rem" }}>
                                      Eşit Ağırlık
                                  </div>

                                  <div className='col-3 d-flex flex-column align-items-center justify-content-center' style={{ marginLeft: 25, width: 65, textAlign: "center", fontSize: "0.7rem" }}>
                                      Dil
                                  </div>
                              </div>
                          </div>
                          <div className="d-flex align-items-center" style={{ width: "100%" }}>
                              <span className="netler-input" style={{ width: 65}}>
                                  <b>
                                  Ham Puan
                                  </b>
                              </span>
                              <div className="row" style={{ marginLeft: 15 }}>
                                  <div className='col-3 d-flex flex-column align-items-center' >
                                      <Input value={GetThreeDigitN(state.ayt_data.sayisal_ham_puan)} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                                      <div className="netler-input">
                                      {state.ayt_data.sayisal_ham_puan == 0 ? "Sıralama Yok" : state.ayt_data.sayisal_ham_basari_sirasi}
                                      </div>
                                  </div>
                                  
                                  <div className='col-3 d-flex flex-column align-items-center' >
                                      <Input value={GetThreeDigitN(state.ayt_data.sozel_ham_puan)} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                                      <div className="netler-input">
                                      {state.ayt_data.sozel_ham_puan == 0 ? "Sıralama Yok" : state.ayt_data.sozel_ham_basari_sirasi}
                                      </div>
                                  </div>

                                  <div className='col-3 d-flex flex-column align-items-center' >
                                      <Input value={GetThreeDigitN(state.ayt_data.ea_ham_puan)} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                                      <div className="netler-input">
                                      {state.ayt_data.ea_ham_puan == 0 ? "Sıralama Yok" : state.ayt_data.ea_ham_basari_sirasi}
                                      </div>
                                  </div>

                                  <div className='col-3 d-flex flex-column align-items-center' >
                                      <Input value={GetThreeDigitN(state.ayt_data.dil_ham_puan)} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                                      <div className="netler-input">
                                      {state.ayt_data.dil_ham_puan == 0 ? "Sıralama Yok" : state.ayt_data.dil_ham_basari_sirasi}
                                      </div>
                                  </div>
                              </div>
                          </div>

                          <div className="d-flex align-items-center" style={{ width: "100%" }}>
                          <span className="netler-input" style={{ width: 65}}>
                                  <b>
                                  Yerleştirme Puan
                                  </b>
                              </span>
                              <div className="row" style={{ marginLeft: 15 }}>
                                  <div className='col-3 d-flex flex-column align-items-center' >
                                      <Input value={GetThreeDigitN(state.ayt_data.sayisal_yerlestirme_puan)} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                                      <div className="netler-input">
                                        {state.ayt_data.sayisal_yerlestirme_puan == 0 ? "Sıralama Yok" : state.ayt_data.sayisal_yerlestirme_sirasi}
                                      </div>
                                  </div>
                                  
                                  <div className='col-3 d-flex flex-column align-items-center' >
                                      <Input value={GetThreeDigitN(state.ayt_data.sozel_yerlestirme_puan)} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                                      <div className="netler-input">
                                      {state.ayt_data.sozel_yerlestirme_puan == 0 ? "Sıralama Yok" : state.ayt_data.sozel_yerlestirme_sirasi}
                                      </div>
                                  </div>

                                  <div className='col-3 d-flex flex-column align-items-center' >
                                      <Input value={GetThreeDigitN(state.ayt_data.ea_yerlestirme_puan)} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                                      <div className="netler-input">
                                      {state.ayt_data.ea_yerlestirme_puan == 0 ? "Sıralama Yok" : state.ayt_data.ea_yerlestirme_sirasi}
                                      </div>
                                  </div>

                                  <div className='col-3 d-flex flex-column align-items-center' >
                                      <Input value={GetThreeDigitN(state.ayt_data.dil_yerlestirme_puan)} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                                      <div className="netler-input">
                                      {state.ayt_data.dil_yerlestirme_puan == 0 ? "Sıralama Yok" : state.ayt_data.dil_yerlestirme_sirasi}
                                      </div>
                                  </div>
                              </div>
                          </div>

                          <div className="d-flex justify-content-center align-items-center mt-2" style={{ width: "100%", fontSize: "0.75rem" }}>
                              YKS PUANLARINIZ
                          </div>
                        </div>
                      </Grid>
                  </Grid>

                  <Grid sx={{ display: { xs: 'flex', md: 'none' } }} container spacing={1}>
                    <Grid item xs={12} display="flex" justifyContent={"center"}>
                      <Box sx={{ typography: 'body1' }}>
                        <Tabs value={mobileTabs} aria-label="results_tabs" onChange={(e, val) => setMobileTabs(val)}>
                          <Tab label="Puanlar" value={0} />
                          <Tab label="Okullar"  value={1} />
                        </Tabs>
                      </Box>
                    </Grid>
                    
                    {mobileTabs == 0 && 
                    <>
                    <Grid item xs={12} container spacing={3} alignItems="center" >
                      <Grid item sm={4} >
                          <span className="netler-input">
                              <b>
                              Puan Türü:
                              </b>
                          </span>
                      </Grid>

                      <Grid item sm={4} display="flex" alignItems={"center"} flexDirection="column" marginLeft={'auto'}>
                          <span className="netler-input">
                              <b>
                              Ham Puan:
                              </b>
                          </span>
                      </Grid>

                      <Grid item sm={4} display="flex" alignItems={"center"} flexDirection="column" >
                          <span className="netler-input">
                              <b>
                              Yerleştir. Puanı:
                              </b>
                          </span>
                      </Grid>
                    </Grid>
                    
                    <Grid item xs={12} container spacing={3} alignItems="center" >
                      <Grid item sm={4} >
                          <span className="netler-input">
                              <b>
                              Tyt:
                              </b>
                          </span>
                      </Grid>

                      <Grid item sm={4} display="flex" alignItems={"center"} flexDirection="column" marginLeft={'auto'}>
                          <Input value={GetThreeDigitN(state.tyt_data.ham_puan)} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                          <div className="netler-input mt-1">
                          {state.tyt_data.ham_basari_sirasi == 0 ? "Sıralama Yok" : numberWithCommas(state.tyt_data.ham_basari_sirasi)}
                          </div>
                      </Grid>

                      <Grid item sm={4} display="flex" alignItems={"center"} flexDirection="column" >
                          <Input value={GetThreeDigitN(state.tyt_data.yerlestirme_puan)} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                          <div className="netler-input mt-1">
                          {state.tyt_data.yerlestirme_basari_sirasi == 0 ? "Sıralama Yok" : numberWithCommas(state.tyt_data.yerlestirme_basari_sirasi)}
                          </div>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} container spacing={3} alignItems="center" >
                      <Grid item sm={4} >
                          <span className="netler-input">
                              <b>
                              Sayısal:
                              </b>
                          </span>
                      </Grid>

                      <Grid item sm={4} display="flex" alignItems={"center"} flexDirection="column" marginLeft={'auto'}>
                          <Input value={GetThreeDigitN(state.ayt_data.sayisal_ham_puan)} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                          <div className="netler-input mt-1">
                          {state.ayt_data.sayisal_ham_basari_sirasi == 0 ? "Sıralama Yok" : numberWithCommas(state.ayt_data.sayisal_ham_basari_sirasi)}
                          </div>
                      </Grid>

                      <Grid item sm={4} display="flex" alignItems={"center"} flexDirection="column" >
                          <Input value={GetThreeDigitN(state.ayt_data.sayisal_yerlestirme_puan)} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                          <div className="netler-input mt-1">
                          {state.ayt_data.sayisal_yerlestirme_sirasi == 0 ? "Sıralama Yok" : numberWithCommas(state.ayt_data.sayisal_yerlestirme_sirasi)}
                          </div>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} container spacing={3} alignItems="center" >
                      <Grid item sm={4} >
                          <span className="netler-input">
                              <b>
                              Sözel:
                              </b>
                          </span>
                      </Grid>

                      <Grid item sm={4} display="flex" alignItems={"center"} flexDirection="column" marginLeft={'auto'}>
                          <Input value={GetThreeDigitN(state.ayt_data.sozel_ham_puan)} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                          <div className="netler-input mt-1">
                          {state.ayt_data.sozel_ham_basari_sirasi == 0 ? "Sıralama Yok" : numberWithCommas(state.ayt_data.sozel_ham_basari_sirasi)}
                          </div>
                      </Grid>

                      <Grid item sm={4} display="flex" alignItems={"center"} flexDirection="column" >
                          <Input value={GetThreeDigitN(state.ayt_data.sozel_yerlestirme_puan)} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                          <div className="netler-input mt-1">
                          {state.ayt_data.sozel_yerlestirme_sirasi == 0 ? "Sıralama Yok" : numberWithCommas(state.ayt_data.sozel_yerlestirme_sirasi)}
                          </div>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} container spacing={3} alignItems="center" >
                      <Grid item sm={4} >
                          <span className="netler-input">
                              <b>
                              Eşit Ağır.
                              </b>
                          </span>
                      </Grid>

                      <Grid item sm={4} display="flex" alignItems={"center"} flexDirection="column" marginLeft={'auto'}>
                          <Input value={GetThreeDigitN(state.ayt_data.ea_ham_puan)} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                          <div className="netler-input mt-1">
                          {state.ayt_data.ea_ham_basari_sirasi == 0 ? "Sıralama Yok" : numberWithCommas(state.ayt_data.ea_ham_basari_sirasi)}
                          </div>
                      </Grid>

                      <Grid item sm={4} display="flex" alignItems={"center"} flexDirection="column" >
                          <Input value={GetThreeDigitN(state.ayt_data.ea_yerlestirme_puan)} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                          <div className="netler-input mt-1">
                          {state.ayt_data.ea_yerlestirme_sirasi == 0 ? "Sıralama Yok" : numberWithCommas(state.ayt_data.ea_yerlestirme_sirasi)}
                          </div>
                      </Grid>
                      
                    </Grid>

                    <Grid item xs={12} container spacing={3} alignItems="center" >
                      <Grid item sm={4} >
                          <span className="netler-input">
                              <b>
                              Tyt:
                              </b>
                          </span>
                      </Grid>

                      <Grid item sm={4} display="flex" alignItems={"center"} flexDirection="column" marginLeft={'auto'} >
                          <Input value={GetThreeDigitN(state.ayt_data.dil_ham_puan)} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                          <div className="netler-input mt-1">
                          {state.ayt_data.dil_ham_basari_sirasi == 0 ? "Sıralama Yok" : numberWithCommas(state.ayt_data.dil_ham_basari_sirasi)}
                          </div>
                      </Grid>

                      <Grid item sm={4} display="flex" alignItems={"center"} flexDirection="column" >
                          <Input value={GetThreeDigitN(state.ayt_data.dil_yerlestirme_puan)} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                          <div className="netler-input mt-1">
                          {state.ayt_data.dil_yerlestirme_sirasi == 0 ? "Sıralama Yok" : numberWithCommas(state.ayt_data.dil_yerlestirme_sirasi)}
                          </div>
                      </Grid>
                    </Grid>
                    </>}
                  </Grid>
                </div>
          
                
                <Box sx={{ display: { xs: 'none', md: "flex" } }} flexDirection="column">
                  <div className='sonuc-listesi p-2'>
                    <span className='p-2 d-flex align-center' style={{ position: "relative", width: "100%" }}>
                      <div className="d-flex" style={{ width: "100%" }}>
                      Sonuç Listesi 2022 - {query.get("pturu") == "tyt" ? "TYT" :
                                                    query.get("pturu") == "say" ? "Sayısal" :
                                                    query.get("pturu") == "söz" ? "Sözel" :
                                                    query.get("pturu") == "dil" ? "Dil" :
                                                    query.get("pturu") == "null" ? "Lütfen bir alan seçin" :
                                                    query.get("pturu") == undefined ? "Lütfen bir alan seçin" :
                                                    query.get("pturu") == "ea" && "Eşit Ağırlık"  
                                                    
                                                    }

                        

                        <div style={{ position: "relative", marginLeft: "auto", marginRight: 0, display: "flex", alignItems: "center" }}>
                        <div className="row" style={{ marginLeft: "auto", marginRight: 0, justifyContent: "end" }} >
                            
                            <div className="col-2">
                            <Link to={GetPuanTuru("tyt")}>
                            <Button size="sm" style={{ backgroundColor: query.get('pturu') == "tyt" ? "rgb(243, 212, 183)" : "", color: query.get('pturu') == "tyt" ? "black" : "" }}>
                              TYT
                            </Button>
                            </Link>
                            </div>

                            <div className="col-2">
                            <Link to={GetPuanTuru("say")}>
                            <Button size="sm" style={{ backgroundColor: query.get('pturu') == "say" ? "rgb(243, 212, 183)" : "", color: query.get('pturu') == "say" ? "black" : "" }}>
                              Say.
                            </Button>
                            </Link>
                            </div>

                            <div className="col-2">
                            <Link to={GetPuanTuru("söz")}>
                            <Button size="sm" style={{ backgroundColor: query.get('pturu') == "söz" ? "rgb(243, 212, 183)" : "", color: query.get('pturu') == "söz" ? "black" : "" }}>
                              Söz.
                            </Button>
                            </Link>
                            </div>

                            <div className="col-2">
                            <Link to={GetPuanTuru("ea")}>
                            <Button size="sm" style={{ backgroundColor: query.get('pturu') == "ea" ? "rgb(243, 212, 183)" : "", color: query.get('pturu') == "ea" ? "black" : "" }}>
                              E.A.
                            </Button>
                            </Link>
                            </div>

                            <div className="col-2">
                            <Link to={GetPuanTuru("dil")}>
                              <Button size="sm" style={{ backgroundColor: query.get('pturu') == "dil" ? "rgb(243, 212, 183)" : "", color: query.get('pturu') == "dil" ? "black" : "" }}>
                                Dil
                              </Button>
                            </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </span>

                    {(query.get('pturu') != undefined && query.get('pturu') != 'null') && 
                    <>
                    

                    {selectedSchools.length > 0 ? 
                    <>
                    <Table striped hover style={{ position: "relative", minHeight: 334 }}>
                    {tableLoading && <Loader style={{ height: "100%" }} />}
                    <thead>
                      <tr>
                        <th>
                          YOP Kodu
                        </th>
                        <th>
                          Üniversite
                        </th>
                        <th>
                          Program Adı
                        </th>
                        <th>
                          İl
                        </th>

                        <th>
                          Tür
                        </th>

                        <th>
                          Ücret/Burs
                        </th>

                        <th>
                          Öğretim Türü
                        </th>

                        <th>
                          Kontenjan
                        </th>

                        <th>
                          Taban Puan
                        </th>

                        <th>
                          Sıralama
                        </th>
                      </tr>
                    </thead>

                    <tbody >
                      {!tableLoading &&
                      <>
                      {selectedSchools.map((item:any, index) => {


                        return(
                          <tr>
                            <th>
                              <a href={"https://yokatlas.yok.gov.tr/" + item.yop_kodu} target="_blank">{item.yop_kodu.split("=")[1]}</a>
                            </th>

                            <th title={item.universite}>
                              <div className="cut-text">
                              {item.universite}
                              </div>
                            </th>

                            <th title={item.program_adi}>
                              <div className="cut-text">
                              {item.program_adi}
                              </div>
                            </th>

                            <th>
                              {item.sehir}
                            </th>

                            <th>
                              {item.universite_turu}
                            </th>

                            <th>
                              {item.burs_statu}
                            </th>

                            <th>
                              {item.ogretim_statu}
                            </th>

                            <th>
                              {item.kontenjan[1]}
                            </th>

                            <th>
                            {item.taban[0] == "0" ? "-" : item.taban[0]}
                            </th>

                            <th>
                            {item.tbs[0] === "30000000" ? "---" : item.tbs[0] === "20000000" ? "Dolmadı" : item.tbs[0].toString()}
                            </th>
                          </tr>
                        )
                      })}
                      </>}
                    </tbody>
                    </Table>
                    </>
                    :
                    <>
                    <Box width={"100%"} height={334} display="flex" alignItems="center" justifyContent={"center"} position="relative">
                      {tableLoading && <Loader style={{ height: "100%" }} />}
                      Puanınıza uygun okul bulunamadı
                    </Box>
                    </>}
                    {!tableLoading && <PaginatedItems itemsPerPage={8} pageCount={PaginationData.last_page} items={Schools} changeCurrentItems={(items:any) => setSelectedSchools(items)} />}
                    </>
                    }
                  </div>

                
                </Box>

                <Box sx={{ display: { xs: 'flex', md: "none" } }} display="flex" flexDirection={"column"} padding={3}>
                  {mobileTabs == 1 && <>
                    <div className='sonuc-listesi p-2'>
                    <div className="d-flex" style={{ width: "100%", flexDirection:"column", alignItems: "center", justifyContent: "center" }}>
                        <span>
                        Sonuç Listesi 2022 - {query.get("pturu") == "tyt" ? "TYT" :
                                                    query.get("pturu") == "say" ? "Sayısal" :
                                                    query.get("pturu") == "söz" ? "Sözel" :
                                                    query.get("pturu") == "dil" ? "Dil" :
                                                    query.get("pturu") == "null" ? "Lütfen bir alan seçin" :
                                                    query.get("pturu") == undefined ? "Lütfen bir alan seçin" :
                                                    query.get("pturu") == "ea" && "Eşit Ağırlık"  
                                                    
                                                    }
                        </span>

                        <div className="row mt-3" style={{ justifyContent: "center" }} >
                            
                            <div className="col-2">
                            <Link to={GetPuanTuru("tyt")}>
                            <Button size="sm" style={{ backgroundColor: query.get('pturu') == "tyt" ? "rgb(243, 212, 183)" : "", color: query.get('pturu') == "tyt" ? "black" : "" }}>
                              TYT
                            </Button>
                            </Link>
                            </div>

                            <div className="col-2">
                            <Link to={GetPuanTuru("say")}>
                            <Button size="sm" style={{ backgroundColor: query.get('pturu') == "say" ? "rgb(243, 212, 183)" : "", color: query.get('pturu') == "say" ? "black" : "" }}>
                              Say.
                            </Button>
                            </Link>
                            </div>

                            <div className="col-2">
                            <Link to={GetPuanTuru("söz")}>
                            <Button size="sm" style={{ backgroundColor: query.get('pturu') == "söz" ? "rgb(243, 212, 183)" : "", color: query.get('pturu') == "söz" ? "black" : "" }}>
                              Söz.
                            </Button>
                            </Link>
                            </div>

                            <div className="col-2">
                            <Link to={GetPuanTuru("ea")}>
                            <Button size="sm" style={{ backgroundColor: query.get('pturu') == "ea" ? "rgb(243, 212, 183)" : "", color: query.get('pturu') == "ea" ? "black" : "" }}>
                              E.A.
                            </Button>
                            </Link>
                            </div>

                            <div className="col-2">
                            <Link to={GetPuanTuru("dil")}>
                              <Button size="sm" style={{ backgroundColor: query.get('pturu') == "dil" ? "rgb(243, 212, 183)" : "", color: query.get('pturu') == "dil" ? "black" : "" }}>
                                Dil
                              </Button>
                            </Link>
                            </div>
                          </div>
                      </div>

                    {(query.get('pturu') != undefined && query.get('pturu') != 'null') && 
                    <>
                    <Table striped hover style={{ minHeight: 334, overflow :"hidden", position: "relative" }}>
                    {tableLoading && <Loader style={{  height: "100%", position: "absolute", left: 0, top: 0 }} />}
                    <thead>
                      <tr>
                        <th>
                          YOP Kodu
                        </th>
                        <th>
                          Üniversite Bilgileri
                        </th>

                        <th>
                          Puan ve Sıralama 
                        </th>
                      </tr>
                    </thead>

                    <tbody >
                      {!tableLoading &&
                      <>
                      {selectedSchools.map((item:any, index) => {
                        return(
                          <tr>
                            <th>
                              <a href={"https://yokatlas.yok.gov.tr/" + item.yop_kodu} target="_blank">{item.yop_kodu.split("=")[1]}</a>
                            </th>

                            <th title={item.universite}>
                              <div className="cut-text">
                              {item.universite}
                              </div>

                              <div >
                              Program Adı: {item.program_adi}
                              </div>

                              <div >
                              İl: {item.sehir}
                              </div>

                              <div >
                              Öğretim Türü: {item.ogretim_statu}
                              </div>
                            </th>

                            <th>
                              <div>
                              Kontenjan: {item.kontenjan[1]}
                              </div>
                              <div>
                              Sıralama: {item.tbs[0] === "30000000" ? "---" : item.tbs[0] === "20000000" ? "Dolmadı" : item.tbs[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}

                              </div>
                            </th>
                          </tr>
                        )
                      })}
                      </>}
                    </tbody>
                    </Table>
                    {!tableLoading && <PaginatedItems itemsPerPage={10} pageCount={PaginationData.last_page} items={Schools} changeCurrentItems={(items:any) => setSelectedSchools(items)} />}
                    </>
                    }
                  </div>
                  </>}
                
                  <Grid container spacing={2} direction="column">
                    <Grid item>
                    <div style={{ width :"100%", textAlign: "center" }}>
                      <b>Geri</b> butonuna basarak hesaplamaya geri dönebilirsin
                    </div>
                    </Grid>
                    <Grid item>
                    <Link to="/yks/hesapla">
                      <Button  style={{width: "100%", backgroundColor: 'rgb(243, 212, 183)' }} className='sonuc-button'>
                          Geri
                      </Button>
                      </Link>
                    </Grid>
                  </Grid>
                </Box>
              </Route>
          </div>
      </Route>
    )
}

export default Yks;

/**
 * 
 * <Link style={{ marginLeft: 5 }} to={"/yks/get/?on=Eray%20Aydın&odp=75&tq1=35&tq2=18&tq3=15&tq4=15&thp=373.554&typ=396.054&aq1=35&aq2=10&aq3=3&aq4=8&aq5=24&aq6=8&aq7=3&aq8=4&aq9=8&aq10=8&aq11=6&aq12=8&ashp=384.335&asyp=406.835&asohp=398.117&asoyp=420.617&aehp=438.620&aeyp=461.120&adhp=260.829&adyp=283.329&pturu=tyt"}>
                       tıklayın
                      </Link>
 */