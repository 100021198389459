import { ReactHTMLElement } from "react";
import { InfinitySpin } from "react-loader-spinner";

interface ILoader extends React.HTMLProps<HTMLDivElement>{

}

function Loader(props:ILoader) {
    

    return(
        <div {...props} className="loader">
            <InfinitySpin 
            width='200'
            color="rgb(51, 51, 51, 0.8)"
            />
        </div>
    )
}

export default Loader;