import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { Route, useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { Button, CloseButton, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Pagination, PaginationItem, PaginationLink, Table } from 'reactstrap';
import Loader from "./Loader";
import PaginatedItems from "./Paginate";
import { AiFillInfoCircle } from "react-icons/ai";
import { Box, Grid } from "@mui/material";
import { toast } from "react-toastify";

function LgsCalculate() {
    const history = useHistory();

    const [warning, setWarning] = useState({
        statu: false,
        text: "Lütfen doğru ve yanlış sayısını kontrol edin. Toplam sayı, soru sayısını geçemez."
    });

    const [isLoading, setLoading] = useState(false);

    const [state, setState] = useState({
        ogrenci_adi: "None",
        calculated: false,
        calculatedURL: ""
    }); 

    const [calcValues, setCalcValues] = useState({
        turkce: {
            dogru: 0,
            yanlis: 0,
            max_toplam: 20
        },

        inkilap: {
            dogru: 0,
            yanlis: 0,
            max_toplam: 10
        },

        din: {
            dogru: 0,
            yanlis: 0,
            max_toplam: 10
        },

        ydil: {
            dogru: 0,
            yanlis: 0,
            max_toplam: 10
        },

        mat: {
            dogru: 0,
            yanlis: 0,
            max_toplam: 20
        },

        fen: {
            dogru: 0,
            yanlis: 0,
            max_toplam: 20
        }
    });

    const location = useLocation();


    useEffect(() => {
        if(location.pathname.includes("hesapla")){
          
            setCalcValues({
                turkce: {
                    dogru: 0,
                    yanlis: 0,
                    max_toplam: 20
                },
        
                inkilap: {
                    dogru: 0,
                    yanlis: 0,
                    max_toplam: 10
                },
        
                din: {
                    dogru: 0,
                    yanlis: 0,
                    max_toplam: 10
                },
        
                ydil: {
                    dogru: 0,
                    yanlis: 0,
                    max_toplam: 10
                },
        
                mat: {
                    dogru: 0,
                    yanlis: 0,
                    max_toplam: 20
                },
        
                fen: {
                    dogru: 0,
                    yanlis: 0,
                    max_toplam: 20
                }
            })  
        }
        
      }, [location.pathname])

    function Calculate() {
        if(calcValues.turkce.dogru + calcValues.turkce.yanlis > calcValues.turkce.max_toplam){
            toast.warn("Lütfen türkçe doğru ve yanlış sayısını kontrol edin. Toplam sayı, soru sayısını geçemez.", {
                position: "top-center",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            //setWarning({statu: true, text: "Lütfen türkçe doğru ve yanlış sayısını kontrol edin. Toplam sayı, soru sayısını geçemez."})
            return
        }

        if(calcValues.inkilap.dogru + calcValues.inkilap.yanlis > calcValues.inkilap.max_toplam){
            toast.warn("Lütfen T.C. İnk. Tar. Ve Atatürk doğru ve yanlış sayısını kontrol edin. Toplam sayı, soru sayısını geçemez.", {
                position: "top-center",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            //setWarning({statu: true, text: "Lütfen T.C. İnk. Tar. Ve Atatürk. doğru ve yanlış sayısını kontrol edin. Toplam sayı, soru sayısını geçemez."})
            return
        }

        if(calcValues.din.dogru + calcValues.din.yanlis > calcValues.din.max_toplam){
            toast.warn("Lütfen din doğru ve yanlış sayısını kontrol edin. Toplam sayı, soru sayısını geçemez.", {
                position: "top-center",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            //setWarning({statu: true, text: "Lütfen din kültürü doğru ve yanlış sayısını kontrol edin. Toplam sayı, soru sayısını geçemez."})
            return
        }

        if(calcValues.ydil.dogru + calcValues.ydil.yanlis > calcValues.ydil.max_toplam){
            toast.warn("Lütfen yabancı dil doğru ve yanlış sayısını kontrol edin. Toplam sayı, soru sayısını geçemez.", {
                position: "top-center",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            //setWarning({statu: true, text: "Lütfen yabancı dil doğru ve yanlış sayısını kontrol edin. Toplam sayı, soru sayısını geçemez."})
            return
        }

        if(calcValues.fen.dogru + calcValues.fen.yanlis > calcValues.fen.max_toplam){
            toast.warn("Lütfen fen bilimleri doğru ve yanlış sayısını kontrol edin. Toplam sayı, soru sayısını geçemez.", {
                position: "top-center",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            //setWarning({statu: true, text: "Lütfen fen bilimleri doğru ve yanlış sayısını kontrol edin. Toplam sayı, soru sayısını geçemez."})
            return
        }

        if(calcValues.mat.dogru + calcValues.mat.yanlis > calcValues.mat.max_toplam){
            toast.warn("Lütfen matematik doğru ve yanlış sayısını kontrol edin. Toplam sayı, soru sayısını geçemez.", {
                position: "top-center",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            //setWarning({statu: true, text: "Lütfen matematik doğru ve yanlış sayısını kontrol edin. Toplam sayı, soru sayısını geçemez."})
            return
        }

        var total_calcValues = calcValues.mat.dogru + calcValues.turkce.dogru + calcValues.ydil.dogru + calcValues.inkilap.dogru + calcValues.fen.dogru + calcValues.din.dogru +
        calcValues.mat.yanlis + calcValues.turkce.yanlis + calcValues.ydil.yanlis + calcValues.inkilap.yanlis + calcValues.fen.yanlis + calcValues.din.yanlis;

        if(total_calcValues < 1) {
            toast.warn("Lütfen doğru değerler giriniz.", {
                position: "top-center",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            //setWarning({statu: true, text: "Lütfen matematik doğru ve yanlış sayısını kontrol edin. Toplam sayı, soru sayısını geçemez."})
            return
        }
        
        
        

        setWarning({statu: false, text: ""})
        setLoading(true);
        const formData = new FormData();
        formData.append("values", JSON.stringify(calcValues));
        //https://tercihrobotu.testprojesi.com:5000
        //http://localhost:5000
        axios.post("https://tercihrobotu.testprojesi.com/api/lgs-calc", formData)
        .then((res) => {
            var data = res.data
            setTimeout(() => {
                setState({...state, calculated: true, calculatedURL: data});
                history.push(data);
                setLoading(false);    
            }, 1200);
        }).catch(err => {
            console.log(err);
            setLoading(false);  
            setWarning({statu: true, text: "Bir hata meydana geldi, lütfen daha sonra tekrar deneyin."})
        })
    }

    return(
        <>
        <Route path={"/lgs/hesapla"}>
            <Box sx={{ display: { xs: 'none', md: 'flex' } }} justifyContent="center">
            <div className='main'>
              {isLoading && <Loader style={{ backgroundColor: "rgba(255, 255, 255, 0.6)", zIndex: 51 }} />}
                <div className='d-flex'>
                    <div className='d-flex flex-column ' style={{ marginLeft: "auto", marginRight: "auto" }} >
                    <div className="d-flex align-items-center" >
                        <span className="netler-input" style={{ width: 40}}>
                            
                        </span>
                        <div className="row" style={{ marginLeft: 25 }}>
                            <div className='col-3 d-flex flex-column align-items-center justify-content-end' style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                                Türkçe
                            </div>
                            
                            <div className='col-3 d-flex flex-column align-items-center justify-content-end'style={{ marginLeft: 25, width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                            T.C. İnk. Tar. ve Atatürk.
                            </div>

                            <div className='col-3 d-flex flex-column align-items-center justify-content-end' style={{ marginLeft: 25, width: 65, textAlign: "center", fontSize: "0.7rem" }}>
                                Din
                            </div>

                            <div className='col-3 d-flex flex-column align-items-center justify-content-end' style={{ marginLeft: 25, width: 65, textAlign: "center", fontSize: "0.7rem" }}>
                                Yabancı Dil
                            </div>

                            <div className='col-3 d-flex flex-column align-items-center justify-content-end' style={{ marginLeft: 25, width: 65, textAlign: "center", fontSize: "0.7rem" }}>
                                Matematik
                            </div>

                            <div className='col-3 d-flex flex-column align-items-center justify-content-end' style={{ marginLeft: 25, width: 65, textAlign: "center", fontSize: "0.7rem" }}>
                            Fen Bilimleri
                            </div>
                        </div>
                    </div>
            
                    <div className="d-flex align-items-center" style={{ width: "100%" }}>
                        <span className="netler-input" style={{ width: 40}}>
                            <b>
                            Doğru : 
                            </b>
                        </span>
                        <div className="row" style={{ marginLeft: 15 }}>
                            <div className='col d-flex flex-column align-items-center' >
                                <Input 
                                onChange={(e) => setCalcValues({...calcValues, turkce: {...calcValues.turkce, dogru: Number(e.target.value)}})}
                                type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                                <div className="netler-input">
                                
                                </div>
                            </div>
                            
                            <div className='col d-flex flex-column align-items-center' >
                                <Input 
                                onChange={(e) => setCalcValues({...calcValues, inkilap: {...calcValues.inkilap, dogru: Number(e.target.value)}})}
                                type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                                <div className="netler-input">
                                
                                </div>
                            </div>

                            <div className='col d-flex flex-column align-items-center' >
                                <Input 
                                onChange={(e) => setCalcValues({...calcValues, din: {...calcValues.din, dogru: Number(e.target.value)}})}
                                type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                                <div className="netler-input">
                                
                                </div>
                            </div>

                            <div className='col d-flex flex-column align-items-center' >
                                <Input 
                                onChange={(e) => setCalcValues({...calcValues, ydil: {...calcValues.ydil, dogru: Number(e.target.value)}})}
                                type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                                <div className="netler-input">
                                </div>
                            </div>

                            <div className='col d-flex flex-column align-items-center' >
                                <Input 
                                onChange={(e) => setCalcValues({...calcValues, mat: {...calcValues.mat, dogru: Number(e.target.value)}})}
                                type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                                <div className="netler-input">
                                </div>
                            </div>

                            <div className='col d-flex flex-column align-items-center' >
                                <Input 
                                onChange={(e) => setCalcValues({...calcValues, fen: {...calcValues.fen, dogru: Number(e.target.value)}})}
                                type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                                <div className="netler-input">
                                </div>
                            </div>
                            
                        </div>
                    </div>

                    <div className="d-flex align-items-center" style={{ width: "100%" }}>
                    <span className="netler-input" style={{ width: 40}}>
                            <b>
                            Yanlış :
                            </b>
                        </span>
                        <div className="row" style={{ marginLeft: 15 }}>
                            <div className='col d-flex flex-column align-items-center' >
                                <Input 
                                onChange={(e) => setCalcValues({...calcValues, turkce: {...calcValues.turkce, yanlis: Number(e.target.value)}})}
                                type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                                <div className="netler-input">
                                    20 Soru
                                </div>
                            </div>
                            
                            <div className='col d-flex flex-column align-items-center' >
                                <Input 
                                onChange={(e) => setCalcValues({...calcValues, inkilap: {...calcValues.inkilap, yanlis: Number(e.target.value)}})}
                                type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                                <div className="netler-input">
                                10 Soru
                                </div>
                            </div>

                            <div className='col d-flex flex-column align-items-center' >
                                <Input 
                                onChange={(e) => setCalcValues({...calcValues, din: {...calcValues.din, yanlis: Number(e.target.value)}})}
                                type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                                <div className="netler-input">
                                10 Soru
                                </div>
                            </div>

                            <div className='col d-flex flex-column align-items-center' >
                                <Input 
                                onChange={(e) => setCalcValues({...calcValues, ydil: {...calcValues.ydil, yanlis: Number(e.target.value)}})}
                                type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                                <div className="netler-input">
                                10 Soru
                                </div>
                            </div>

                            <div className='col d-flex flex-column align-items-center' >
                                <Input 
                                onChange={(e) => setCalcValues({...calcValues, mat: {...calcValues.mat, yanlis: Number(e.target.value)}})}
                                type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                                <div className="netler-input">
                                20 Soru
                                </div>
                            </div>

                            <div className='col d-flex flex-column align-items-center' >
                                <Input 
                                onChange={(e) => setCalcValues({...calcValues, fen: {...calcValues.fen, yanlis: Number(e.target.value)}})}
                                type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                                <div className="netler-input">
                                20 Soru
                                </div>
                            </div>
                        </div>
                    </div>
                    

                    {warning.statu && 
                    <div className="warning-text d-flex mt-4" style={{ fontSize: "0.75rem" }}>
                        <AiFillInfoCircle />
                        <span>
                        {warning.text} 
                        </span>
                    </div>}
                    </div>
                </div>
            </div>
            </Box>

            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
               <div className="main">
                <Grid direction="row" container justifyContent={"center"} spacing={2}>
                        <Grid container spacing={2} item xs={12} alignItems="center">
                                <Grid item xs={3}>
                                    <div style={{ width: 65, textAlign: "left", fontSize: "0.7rem" }} >
                                        
                                    </div>
                                </Grid>

                                <Grid item xs={3} display="flex" justifyContent={"center"}>
                                    <div className="netler-input">
                                        <b>
                                        Doğru:
                                        </b>
                                    </div>
                                </Grid>

                                <Grid item xs={3} display="flex" justifyContent={"center"}>
                                    <div className="netler-input">
                                        <b>
                                        Yanlış:
                                        </b>
                                    </div>
                                </Grid>

                                <Grid item xs={3} style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                                    <div className='netler-input' style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                                        90 Soru
                                    </div>
                                </Grid>
                            </Grid>
                            
                            <Grid container spacing={2} item xs={12} alignItems="center">
                                <Grid item xs={3}>
                                    <div style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                                        Türkçe
                                    </div>
                                </Grid>

                                <Grid item xs={3}>
                                    <Input 
                                    onChange={(e) => 
                                        setCalcValues({...calcValues, turkce: {...calcValues.turkce, dogru: Number(e.target.value)}})
                                    }
                                    type={"number"} style={{ width: "100%", textAlign: "center", fontSize: "small" }} />
                                </Grid>

                                <Grid item xs={3}>
                                    <Input 
                                    onChange={(e) => 
                                        setCalcValues({...calcValues, turkce: {...calcValues.turkce, yanlis: Number(e.target.value)}})
                                    }
                                    type={"number"} style={{ width: "100%", textAlign: "center", fontSize: "small" }} />
                                </Grid>

                                <Grid item xs={3} style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                                    <div className='netler-input' style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                                        {calcValues.turkce.max_toplam} Soru
                                    </div>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} item xs={12} alignItems="center">
                                <Grid item xs={3}>
                                    <div style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                                        T.C. İnk. Tar. ve Atatürk
                                    </div>
                                </Grid>

                                <Grid item xs={3}>
                                    <Input 
                                    onChange={(e) => 
                                        setCalcValues({...calcValues, inkilap: {...calcValues.inkilap, dogru: Number(e.target.value)}})
                                    }
                                    type={"number"} style={{ width: "100%", textAlign: "center", fontSize: "small" }} />
                                </Grid>

                                <Grid item xs={3}>
                                    <Input 
                                    onChange={(e) => 
                                        setCalcValues({...calcValues, inkilap: {...calcValues.inkilap, yanlis: Number(e.target.value)}})
                                    }
                                    type={"number"} style={{ width: "100%", textAlign: "center", fontSize: "small" }} />
                                </Grid>

                                <Grid item xs={3} style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                                    <div className='netler-input' style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                                        {calcValues.inkilap.max_toplam} Soru
                                    </div>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} item xs={12} alignItems="center">
                                <Grid item xs={3}>
                                    <div style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                                        Din
                                    </div>
                                </Grid>

                                <Grid item xs={3}>
                                    <Input 
                                    onChange={(e) => 
                                        setCalcValues({...calcValues, din: {...calcValues.din, dogru: Number(e.target.value)}})
                                    }
                                    type={"number"} style={{ width: "100%", textAlign: "center", fontSize: "small" }} />
                                </Grid>

                                <Grid item xs={3}>
                                    <Input 
                                    onChange={(e) => 
                                        setCalcValues({...calcValues, din: {...calcValues.din, yanlis: Number(e.target.value)}})
                                    }
                                    type={"number"} style={{ width: "100%", textAlign: "center", fontSize: "small" }} />
                                </Grid>

                                <Grid item xs={3} style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                                    <div className='netler-input' style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                                        {calcValues.din.max_toplam} Soru
                                    </div>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} item xs={12} alignItems="center">
                                <Grid item xs={3}>
                                    <div style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                                        Yabancı Dil
                                    </div>
                                </Grid>

                                <Grid item xs={3}>
                                    <Input 
                                    onChange={(e) => 
                                        setCalcValues({...calcValues, ydil: {...calcValues.ydil, dogru: Number(e.target.value)}})
                                    }
                                    type={"number"} style={{ width: "100%", textAlign: "center", fontSize: "small" }} />
                                </Grid>

                                <Grid item xs={3}>
                                    <Input 
                                    onChange={(e) => 
                                        setCalcValues({...calcValues, ydil: {...calcValues.ydil, yanlis: Number(e.target.value)}})
                                    }
                                    type={"number"} style={{ width: "100%", textAlign: "center", fontSize: "small" }} />
                                </Grid>

                                <Grid item xs={3} style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                                    <div className='netler-input' style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                                        {calcValues.ydil.max_toplam} Soru
                                    </div>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} item xs={12} alignItems="center">
                                <Grid item xs={3}>
                                    <div style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                                        Matematik
                                    </div>
                                </Grid>

                                <Grid item xs={3}>
                                    <Input 
                                    onChange={(e) => 
                                        setCalcValues({...calcValues, mat: {...calcValues.mat, dogru: Number(e.target.value)}})
                                    }
                                    type={"number"} style={{ width: "100%", textAlign: "center", fontSize: "small" }} />
                                </Grid>

                                <Grid item xs={3}>
                                    <Input 
                                    onChange={(e) => 
                                        setCalcValues({...calcValues, mat: {...calcValues.mat, yanlis: Number(e.target.value)}})
                                    }
                                    type={"number"} style={{ width: "100%", textAlign: "center", fontSize: "small" }} />
                                </Grid>

                                <Grid item xs={3} style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                                    <div className='netler-input' style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                                        {calcValues.mat.max_toplam} Soru
                                    </div>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} item xs={12} alignItems="center">
                                <Grid item xs={3}>
                                    <div style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                                        Fen Bilimleri
                                    </div>
                                </Grid>

                                <Grid item xs={3}>
                                    <Input 
                                    onChange={(e) => 
                                        setCalcValues({...calcValues, fen: {...calcValues.fen, dogru: Number(e.target.value)}})
                                    }
                                    type={"number"} style={{ width: "100%", textAlign: "center", fontSize: "small" }} />
                                </Grid>

                                <Grid item xs={3}>
                                    <Input 
                                    onChange={(e) => 
                                        setCalcValues({...calcValues, fen: {...calcValues.fen, yanlis: Number(e.target.value)}})
                                    }
                                    type={"number"} style={{ width: "100%", textAlign: "center", fontSize: "small" }} />
                                </Grid>

                                <Grid item xs={3} style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                                    <div className='netler-input' style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                                        {calcValues.fen.max_toplam} Soru
                                    </div>
                                </Grid>
                            </Grid>
                        
                    </Grid> 
                </div>        
            </Box>

            <div className='explain mt-2 d-flex flex-column justify-content-center' style={{ textAlign: "center" }}>
                {state.ogrenci_adi != "None" && <span>
                    Merhaba, <b>{state.ogrenci_adi}</b>
                </span>}
                
                <span className='detail'>
                Netlerini girdikten sonra <b>hesapla</b> butonuna basarak puanını hesaplayabilirsin.
                </span>
        
                <Button onClick={() => Calculate()} style={{ marginLeft: "auto", marginRight: "auto", width: 200, backgroundColor: 'rgb(243, 212, 183)' }} className='mt-4 sonuc-button'>
                    Sonuçları Getir
                </Button>
            </div>
            </Route>
        </>
    )
}

export default LgsCalculate;