import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./Styles/index.css";
import "./Styles/normalize.css";
import { Router } from 'react-router';
import { createBrowserHistory } from "history";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

const customHistory = createBrowserHistory();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Router history={customHistory}>
    <App />
    <ToastContainer
    position="top-center"
    autoClose={2500}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    theme="light"
    />
  </Router>
);
