import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { Route, useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { Button, CloseButton, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Pagination, PaginationItem, PaginationLink, Table } from 'reactstrap';
import Loader from "./Loader";
import PaginatedItems from "./Paginate";
import { AiFillInfoCircle } from "react-icons/ai";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Grid } from "@mui/material";
import { toast } from "react-toastify";

function YksCalculate() {

    const [activeStep, setActiveStep] = useState(0);

    const history = useHistory();

    const [warning, setWarning] = useState({
        statu: false,
        text: "Lütfen doğru ve yanlış sayısını kontrol edin. Toplam sayı, soru sayısını geçemez."
    });

    const [isLoading, setLoading] = useState(false);

    const [state, setState] = useState({
        ogrenci_adi: "",
        calculated: false,
        calculatedURL: "",
        dip_puan_checkbox: false
    }); 

    const [calcValues, setCalcValues] = useState({
        diploma_puani: 0,
        dil:{
            dogru: 0,
            yanlis: 0,
            max_toplam: 80
        },

        tyt:{
            turkce: {
                dogru: 0,
                yanlis: 0,
                max_toplam: 40
            },
    
            sos: {
                dogru: 0,
                yanlis: 0,
                max_toplam: 20
            },
    
            mat: {
                dogru: 0,
                yanlis: 0,
                max_toplam: 40
            },
    
            fen: {
                dogru: 0,
                yanlis: 0,
                max_toplam: 20
            }
        },

        ayt:{
            edebiyat: {
                dogru: 0,
                yanlis: 0,
                max_toplam: 24
            },
    
            tarih_1: {
                dogru: 0,
                yanlis: 0,
                max_toplam: 10
            },
    
            tarih_2: {
                dogru: 0,
                yanlis: 0,
                max_toplam: 11
            },

            cografya_1: {
                dogru: 0,
                yanlis: 0,
                max_toplam: 6
            },
    
            cografya_2: {
                dogru: 0,
                yanlis: 0,
                max_toplam: 11
            },
    
            felsefe: {
                dogru: 0,
                yanlis: 0,
                max_toplam: 12
            },

            din: {
                dogru: 0,
                yanlis: 0,
                max_toplam: 6
            },
    
            mat: {
                dogru: 0,
                yanlis: 0,
                max_toplam: 40
            },

            fizik: {
                dogru: 0,
                yanlis: 0,
                max_toplam: 14
            },
    
            kimya: {
                dogru: 0,
                yanlis: 0,
                max_toplam: 13
            },

            biyoloji: {
                dogru: 0,
                yanlis: 0,
                max_toplam: 13
            }
        }
    });

    const location = useLocation();

    useEffect(() => {
        if(location.pathname.includes("hesapla")){
            setState({ogrenci_adi: "",
            calculated: false,
            calculatedURL: "",
            dip_puan_checkbox: false})
            setActiveStep(0)
            setCalcValues({
                diploma_puani: 0,
                dil:{
                    dogru: 0,
                    yanlis: 0,
                    max_toplam: 80
                },
        
                tyt:{
                    turkce: {
                        dogru: 0,
                        yanlis: 0,
                        max_toplam: 40
                    },
            
                    sos: {
                        dogru: 0,
                        yanlis: 0,
                        max_toplam: 20
                    },
            
                    mat: {
                        dogru: 0,
                        yanlis: 0,
                        max_toplam: 40
                    },
            
                    fen: {
                        dogru: 0,
                        yanlis: 0,
                        max_toplam: 20
                    }
                },
        
                ayt:{
                    edebiyat: {
                        dogru: 0,
                        yanlis: 0,
                        max_toplam: 24
                    },
            
                    tarih_1: {
                        dogru: 0,
                        yanlis: 0,
                        max_toplam: 10
                    },
            
                    tarih_2: {
                        dogru: 0,
                        yanlis: 0,
                        max_toplam: 11
                    },
        
                    cografya_1: {
                        dogru: 0,
                        yanlis: 0,
                        max_toplam: 6
                    },
            
                    cografya_2: {
                        dogru: 0,
                        yanlis: 0,
                        max_toplam: 11
                    },
            
                    felsefe: {
                        dogru: 0,
                        yanlis: 0,
                        max_toplam: 12
                    },
        
                    din: {
                        dogru: 0,
                        yanlis: 0,
                        max_toplam: 6
                    },
            
                    mat: {
                        dogru: 0,
                        yanlis: 0,
                        max_toplam: 40
                    },
        
                    fizik: {
                        dogru: 0,
                        yanlis: 0,
                        max_toplam: 14
                    },
            
                    kimya: {
                        dogru: 0,
                        yanlis: 0,
                        max_toplam: 13
                    },
        
                    biyoloji: {
                        dogru: 0,
                        yanlis: 0,
                        max_toplam: 13
                    }
                }
            })
        }
    }, [location.pathname])

    function ChangeStep(new_index:number) {
        if(activeStep == 1){
            if(calcValues.tyt.turkce.dogru + calcValues.tyt.turkce.yanlis > calcValues.tyt.turkce.max_toplam){
                toast.warn("Lütfen türkçe doğru ve yanlış sayısını kontrol edin. Toplam sayı, soru sayısını geçemez.", {
                    position: "top-center",
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                //setWarning({statu: true, text: "Lütfen türkçe doğru ve yanlış sayısını kontrol edin. Toplam sayı, soru sayısını geçemez."})
                return
            }
    
            if(calcValues.tyt.mat.dogru + calcValues.tyt.mat.yanlis > calcValues.tyt.mat.max_toplam){
                toast.warn("Lütfen matematik doğru ve yanlış sayısını kontrol edin. Toplam sayı, soru sayısını geçemez.", {
                    position: "top-center",
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                //setWarning({statu: true, text: "Lütfen matematik doğru ve yanlış sayısını kontrol edin. Toplam sayı, soru sayısını geçemez."})
                return
            }
    
            if(calcValues.tyt.sos.dogru + calcValues.tyt.sos.yanlis > calcValues.tyt.sos.max_toplam){
                toast.warn("Lütfen sosyal bilgiler doğru ve yanlış sayısını kontrol edin. Toplam sayı, soru sayısını geçemez.", {
                    position: "top-center",
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                //setWarning({statu: true, text: "Lütfen sosyal bilgiler doğru ve yanlış sayısını kontrol edin. Toplam sayı, soru sayısını geçemez."})
                return
            }
    
            if(calcValues.tyt.fen.dogru + calcValues.tyt.fen.yanlis > calcValues.tyt.fen.max_toplam){
                toast.warn("Lütfen fen bilimleri doğru ve yanlış sayısını kontrol edin. Toplam sayı, soru sayısını geçemez.", {
                    position: "top-center",
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                //setWarning({statu: true, text: "Lütfen fen bilimleri doğru ve yanlış sayısını kontrol edin. Toplam sayı, soru sayısını geçemez."})
                return
            }
            
            

            if(
                ((calcValues.tyt.mat.dogru - (calcValues.tyt.mat.yanlis * 0.25)) + 
                (calcValues.tyt.turkce.dogru - (calcValues.tyt.turkce.yanlis * 0.25)))
                <
                1
            ){
                toast.warn("Puanınızın hesaplanması için matematik ve türkçe testlerinden en az 1 nete sahip olmak zorundasın.", {
                    position: "top-center",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                return
            }
        }else if(activeStep == 0){
            if(state.dip_puan_checkbox == false && (Number(calcValues.diploma_puani) < 50 || Number(calcValues.diploma_puani) > 100)){
                toast.warn("Lütfen geçerli bir diploma puanı giriniz.", {
                    position: "top-center",
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                return
            }
        }else if (activeStep == 2){
            if(calcValues.ayt.edebiyat.dogru + calcValues.ayt.edebiyat.yanlis > calcValues.ayt.edebiyat.max_toplam){
                toast.warn("Lütfen ayt doğru ve yanlış sayısını kontrol edin. Toplam sayı, soru sayısını geçemez.", {
                    position: "top-center",
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                //setWarning({statu: true, text: "Lütfen türkçe doğru ve yanlış sayısını kontrol edin. Toplam sayı, soru sayısını geçemez."})
                return
            }
    
            if(calcValues.ayt.tarih_1.dogru + calcValues.ayt.tarih_1.yanlis > calcValues.ayt.tarih_1.max_toplam){
                toast.warn("Lütfen tarih-1 doğru ve yanlış sayısını kontrol edin. Toplam sayı, soru sayısını geçemez.", {
                    position: "top-center",
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                //setWarning({statu: true, text: "Lütfen matematik doğru ve yanlış sayısını kontrol edin. Toplam sayı, soru sayısını geçemez."})
                return
            }
    
            if(calcValues.ayt.cografya_1.dogru + calcValues.ayt.cografya_1.yanlis > calcValues.ayt.cografya_1.max_toplam){
                toast.warn("Lütfen coğrafya-1 doğru ve yanlış sayısını kontrol edin. Toplam sayı, soru sayısını geçemez.", {
                    position: "top-center",
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                //setWarning({statu: true, text: "Lütfen sosyal bilgiler doğru ve yanlış sayısını kontrol edin. Toplam sayı, soru sayısını geçemez."})
                return
            }
    
            if(calcValues.ayt.cografya_2.dogru + calcValues.ayt.cografya_2.yanlis > calcValues.ayt.cografya_2.max_toplam){
                toast.warn("Lütfen fen bilimleri doğru ve yanlış sayısını kontrol edin. Toplam sayı, soru sayısını geçemez.", {
                    position: "top-center",
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                //setWarning({statu: true, text: "Lütfen fen bilimleri doğru ve yanlış sayısını kontrol edin. Toplam sayı, soru sayısını geçemez."})
                return
            }

            if(calcValues.ayt.felsefe.dogru + calcValues.ayt.felsefe.yanlis > calcValues.ayt.felsefe.max_toplam){
                toast.warn("Lütfen felsefe doğru ve yanlış sayısını kontrol edin. Toplam sayı, soru sayısını geçemez.", {
                    position: "top-center",
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                //setWarning({statu: true, text: "Lütfen fen bilimleri doğru ve yanlış sayısını kontrol edin. Toplam sayı, soru sayısını geçemez."})
                return
            }

            if(calcValues.ayt.din.dogru + calcValues.ayt.din.yanlis > calcValues.ayt.din.max_toplam){
                toast.warn("Lütfen din doğru ve yanlış sayısını kontrol edin. Toplam sayı, soru sayısını geçemez.", {
                    position: "top-center",
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                //setWarning({statu: true, text: "Lütfen fen bilimleri doğru ve yanlış sayısını kontrol edin. Toplam sayı, soru sayısını geçemez."})
                return
            }

            if(calcValues.ayt.mat.dogru + calcValues.ayt.mat.yanlis > calcValues.ayt.mat.max_toplam){
                toast.warn("Lütfen matematik doğru ve yanlış sayısını kontrol edin. Toplam sayı, soru sayısını geçemez.", {
                    position: "top-center",
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                //setWarning({statu: true, text: "Lütfen fen bilimleri doğru ve yanlış sayısını kontrol edin. Toplam sayı, soru sayısını geçemez."})
                return
            }

            if(calcValues.ayt.fizik.dogru + calcValues.ayt.fizik.yanlis > calcValues.ayt.fizik.max_toplam){
                toast.warn("Lütfen fizik doğru ve yanlış sayısını kontrol edin. Toplam sayı, soru sayısını geçemez.", {
                    position: "top-center",
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                //setWarning({statu: true, text: "Lütfen fen bilimleri doğru ve yanlış sayısını kontrol edin. Toplam sayı, soru sayısını geçemez."})
                return
            }

            if(calcValues.ayt.kimya.dogru + calcValues.ayt.kimya.yanlis > calcValues.ayt.kimya.max_toplam){
                toast.warn("Lütfen kimya doğru ve yanlış sayısını kontrol edin. Toplam sayı, soru sayısını geçemez.", {
                    position: "top-center",
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                //setWarning({statu: true, text: "Lütfen fen bilimleri doğru ve yanlış sayısını kontrol edin. Toplam sayı, soru sayısını geçemez."})
                return
            }

            if(calcValues.ayt.biyoloji.dogru + calcValues.ayt.biyoloji.yanlis > calcValues.ayt.biyoloji.max_toplam){
                toast.warn("Lütfen felsefe doğru ve yanlış sayısını kontrol edin. Toplam sayı, soru sayısını geçemez.", {
                    position: "top-center",
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                //setWarning({statu: true, text: "Lütfen fen bilimleri doğru ve yanlış sayısını kontrol edin. Toplam sayı, soru sayısını geçemez."})
                return
            }
        }else if (activeStep == 3){
            if(calcValues.dil.dogru + calcValues.dil.yanlis > calcValues.dil.max_toplam){
                toast.warn("Lütfen yabancı dil doğru ve yanlış sayısını kontrol edin. Toplam sayı, soru sayısını geçemez.", {
                    position: "top-center",
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                //setWarning({statu: true, text: "Lütfen fen bilimleri doğru ve yanlış sayısını kontrol edin. Toplam sayı, soru sayısını geçemez."})
                return
            }
        }

        setActiveStep(new_index);
    }

    function Calculate() {
        if(calcValues.tyt.turkce.dogru + calcValues.tyt.turkce.yanlis > calcValues.tyt.turkce.max_toplam){
            toast.warn("Lütfen türkçe doğru ve yanlış sayısını kontrol edin. Toplam sayı, soru sayısını geçemez.", {
                position: "top-center",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            //setWarning({statu: true, text: "Lütfen türkçe doğru ve yanlış sayısını kontrol edin. Toplam sayı, soru sayısını geçemez."})
            return
        }

        if(calcValues.tyt.mat.dogru + calcValues.tyt.mat.yanlis > calcValues.tyt.mat.max_toplam){
            toast.warn("Lütfen matematik doğru ve yanlış sayısını kontrol edin. Toplam sayı, soru sayısını geçemez.", {
                position: "top-center",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            //setWarning({statu: true, text: "Lütfen matematik doğru ve yanlış sayısını kontrol edin. Toplam sayı, soru sayısını geçemez."})
            return
        }

        if(calcValues.tyt.sos.dogru + calcValues.tyt.sos.yanlis > calcValues.tyt.sos.max_toplam){
            toast.warn("Lütfen sosyal bilgiler doğru ve yanlış sayısını kontrol edin. Toplam sayı, soru sayısını geçemez.", {
                position: "top-center",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            //setWarning({statu: true, text: "Lütfen sosyal bilgiler doğru ve yanlış sayısını kontrol edin. Toplam sayı, soru sayısını geçemez."})
            return
        }

        if(calcValues.tyt.fen.dogru + calcValues.tyt.fen.yanlis > calcValues.tyt.fen.max_toplam){
            toast.warn("Lütfen fen bilimleri doğru ve yanlış sayısını kontrol edin. Toplam sayı, soru sayısını geçemez.", {
                position: "top-center",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            //setWarning({statu: true, text: "Lütfen fen bilimleri doğru ve yanlış sayısını kontrol edin. Toplam sayı, soru sayısını geçemez."})
            return
        }

        if(
            ((calcValues.tyt.mat.dogru - (calcValues.tyt.mat.yanlis * 0.25)) + 
            (calcValues.tyt.turkce.dogru - (calcValues.tyt.turkce.yanlis * 0.25)))
            <
            1
        ){
            toast.warn("Puanınızın hesaplanması için matematik ve türkçe testlerinden en az 1 nete sahip olmak zorundasın.", {
                position: "top-center",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return
        }

        

        setWarning({statu: false, text: ""})
        setLoading(true);

        if(state.dip_puan_checkbox){
            setCalcValues({...calcValues, diploma_puani: 0})
        }

        const formData = new FormData();
        formData.append("values", JSON.stringify(calcValues));
        //"https://tercihrobotu.testprojesi.com:5000
        //http://localhost:5000
        axios.post("https://tercihrobotu.testprojesi.com/api/yks-calc", formData)
        .then((res) => {
            var data = res.data
            console.log(data);
            
            setTimeout(() => {
                setState({...state, calculated: true, calculatedURL: data});
                history.push(data);
                setLoading(false);    
            }, 1200);
        }).catch(err => {
            setLoading(false);   
            console.log(err);
            setWarning({statu: true, text: "Bir hata meydana geldi, lütfen daha sonra tekrar deneyin."})
        })
    }

    function DiplomaPuaniCheckbox(e:any) {
        setState({...state, dip_puan_checkbox: e.target.checked});
    }

    const steps = [
        "Diploma Bilgisi",
        "TYT Bilgileri",
        "AYT Bilgileri",
        "Y. Dil Bilgileri"
    ]

    return(
        <>
        <Route exact path={"/yks/hesapla"}>
              <div className='main' style={{ overflow: "auto", height: 'auto', maxHeight: 500 }}>
              {isLoading && <Loader style={{ backgroundColor: "rgba(255, 255, 255, 0.6)", zIndex: 51 }} />}
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((step, index) => {
                        
                        if(index == activeStep){
                            return(
                                <Step className="MuiSvgIcon-root-drp-active" key={step}>
                                    <StepLabel>
                                        {step}
                                    </StepLabel>
                                </Step>
                            )
                        }

                        return(
                            <Step key={step}>
                                <StepLabel>
                                    {step}
                                </StepLabel>
                            </Step>
                        )
                    })}
                </Stepper>
                

                {activeStep == 0 ? 
                <>
                <Grid container direction="row" spacing={2}  justifyContent={"center"} marginTop={4} marginBottom={4}>
               

                    <Grid item container direction="row" xl={4}  justifyContent={"center"}>
                        <Grid item xs={12}>
                            <span>
                                Diploma Puanı:
                            </span>
                        </Grid>

                        <Grid item xs={12}>
                            <Input 
                            key='calcvalues_diploma_puani'
                            value={Number(calcValues.diploma_puani) == 0 ? '' : calcValues.diploma_puani}
                            disabled={state.dip_puan_checkbox}
                                placeholder="Diploma Puanı"
                                onChange={(e) => 
                                setCalcValues({...calcValues, diploma_puani: Number(e.target.value)})}
                                type={"number"} className='mt-2' style={{ textAlign: "center", fontSize: "small" }} />
                        </Grid>

                        <Grid item xs={12}>
                            <Label className="mt-2 d-flex align-items-center" >
                            <Input type="checkbox" checked={state.dip_puan_checkbox} onChange={(e) => DiplomaPuaniCheckbox(e)} />
                            <span style={{ marginLeft: 5, fontSize: 12 }}>
                                Geçen sene bir programa yerleştim
                            </span>
                            </Label>
                        </Grid>
                    </Grid>
                </Grid>
                </>
                : activeStep == 1 ?
                <>
                <Grid sx={{ display: { xs: 'none', sm: 'flex' } }} direction={"row"} container spacing={1} marginTop={4} marginBottom={4}>
                    <Grid item container justifyContent={"center"} spacing={2}>
                        <Grid item style={{ display: "flex", alignItems: "end", justifyContent: "end" }}>
                            <div style={{ width: 40, fontSize: "1rem" }} >
                                
                            </div>
                        </Grid>

                        <Grid item style={{ display: "flex", alignItems: "end", justifyContent: "end" }}>
                            <div style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                                Türkçe
                            </div>
                        </Grid>

                        <Grid item style={{ display: "flex", alignItems: "end", justifyContent: "end" }}>
                            <div className='col d-flex flex-column align-items-center justify-content-end' style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                                Sosyal Bilimler
                            </div>
                        </Grid>

                        <Grid item style={{ display: "flex", alignItems: "end", justifyContent: "end" }}>
                            <div className='col d-flex flex-column align-items-center justify-content-end' style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                                Matematik
                            </div>
                        </Grid>

                        <Grid item style={{ display: "flex", alignItems: "end", justifyContent: "end" }}>
                            <div className='col d-flex flex-column align-items-center justify-content-end' style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                                Fen Bilimleri
                            </div>
                        </Grid>
                    </Grid>

                    <Grid item container justifyContent={"center"} spacing={2}>
                        <Grid item style={{ display: "flex", alignItems: "center" }}>
                            <span className="netler-input" style={{ width: 40, }}>
                                <b>
                                    Doğru:
                                </b>
                            </span>
                        </Grid>
                        <Grid item>
                            <Input 
                            value={calcValues.tyt.turkce.dogru == 0 ? '' : calcValues.tyt.turkce.dogru}
                            onChange={(e) => 
                            setCalcValues({...calcValues, tyt: {...calcValues.tyt, turkce: {...calcValues.tyt.turkce, dogru: Number(e.target.value)}}})}
                            type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                        </Grid>

                        <Grid item>
                            <Input 
                            value={calcValues.tyt.sos.dogru == 0 ? '' : calcValues.tyt.sos.dogru}
                            onChange={(e) => 
                            setCalcValues({...calcValues, tyt: {...calcValues.tyt, sos: {...calcValues.tyt.sos, dogru: Number(e.target.value)}}})}
                            type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                        </Grid>

                        <Grid item>
                            <Input 
                            value={calcValues.tyt.mat.dogru == 0 ? '' : calcValues.tyt.mat.dogru}
                            onChange={(e) => 
                            setCalcValues({...calcValues, tyt: {...calcValues.tyt, mat: {...calcValues.tyt.mat, dogru: Number(e.target.value)}}})}
                            type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                        </Grid>

                        <Grid item>
                            <Input 
                            value={calcValues.tyt.fen.dogru == 0 ? '' : calcValues.tyt.fen.dogru}
                            onChange={(e) => 
                            setCalcValues({...calcValues, tyt: {...calcValues.tyt, fen: {...calcValues.tyt.fen, dogru: Number(e.target.value)}}})}
                            type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                        </Grid>
                    </Grid>

                    <Grid item container justifyContent={"center"} spacing={2}>
                        <Grid item style={{ display: "flex", alignItems: "center" }}>
                            <span className="netler-input" style={{ width: 40, }}>
                                <b>
                                    Yanlış:
                                </b>
                            </span>
                        </Grid>

                        <Grid item>
                            <Input 
                            value={calcValues.tyt.turkce.yanlis == 0 ? '' : calcValues.tyt.turkce.yanlis}
                            onChange={(e) => 
                            setCalcValues({...calcValues, tyt: {...calcValues.tyt, turkce: {...calcValues.tyt.turkce, yanlis: Number(e.target.value)}}})}
                            type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                        </Grid>

                        <Grid item>
                            <Input 
                            value={calcValues.tyt.sos.yanlis == 0 ? '' : calcValues.tyt.sos.yanlis}
                            onChange={(e) => 
                            setCalcValues({...calcValues, tyt: {...calcValues.tyt, sos: {...calcValues.tyt.sos, yanlis: Number(e.target.value)}}})}
                            type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                        </Grid>

                        <Grid item>
                            <Input 
                            value={calcValues.tyt.mat.yanlis == 0 ? '' : calcValues.tyt.mat.yanlis}
                            onChange={(e) => 
                            setCalcValues({...calcValues, tyt: {...calcValues.tyt, mat: {...calcValues.tyt.mat, yanlis: Number(e.target.value)}}})}
                            type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                        </Grid>

                        <Grid item>
                            <Input 
                            value={calcValues.tyt.fen.yanlis == 0 ? '' : calcValues.tyt.fen.yanlis}
                            onChange={(e) => 
                            setCalcValues({...calcValues, tyt: {...calcValues.tyt, fen: {...calcValues.tyt.fen, yanlis: Number(e.target.value)}}})}
                            type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                        </Grid>
                    </Grid>

                    <Grid item container justifyContent={"center"} spacing={2}>
                        <Grid item style={{ display: "flex", alignItems: "center" }}>
                            <span className="netler-input" style={{ width: 40, }}>
                            </span>
                        </Grid>
                        <Grid item style={{ display: "flex", alignItems: "end", justifyContent: "end" }}>
                            <div className='netler-input' style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                                {calcValues.tyt.turkce.max_toplam} Soru
                            </div>
                        </Grid>

                        <Grid item style={{ display: "flex", alignItems: "end", justifyContent: "end" }}>
                            <div className='netler-input' style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                                {calcValues.tyt.sos.max_toplam} Soru
                            </div>
                        </Grid>

                        <Grid item style={{ display: "flex", alignItems: "end", justifyContent: "end" }}>
                            <div className='netler-input' style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                                {calcValues.tyt.mat.max_toplam} Soru
                            </div>
                        </Grid>

                        <Grid item style={{ display: "flex", alignItems: "end", justifyContent: "end" }}>
                            <div className='netler-input' style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                                {calcValues.tyt.fen.max_toplam} Soru
                            </div>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid sx={{ display: { xs: 'flex', md: 'none' } }} container spacing={1} marginTop={4} marginBottom={4}>
                    <Grid item xl={12} direction="row" container justifyContent={"center"} spacing={2}>
                    <Grid container spacing={2} item xs={12} alignItems="center">
                            <Grid item xs={3}>
                                <div style={{ width: 65, textAlign: "left", fontSize: "0.7rem" }} >
                                    
                                </div>
                            </Grid>

                            <Grid item xs={3} display="flex" justifyContent={"center"}>
                                <div className="netler-input">
                                    <b>
                                    Doğru:
                                    </b>
                                </div>
                            </Grid>

                            <Grid item xs={3} display="flex" justifyContent={"center"}>
                                <div className="netler-input">
                                    <b>
                                    Yanlış:
                                    </b>
                                </div>
                            </Grid>

                            <Grid item xs={3} style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                                <div className='netler-input' style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                                    120 Soru
                                </div>
                            </Grid>
                        </Grid>
                        
                        <Grid container spacing={2} item xs={12} alignItems="center">
                            <Grid item xs={3}>
                                <div style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                                    Türkçe
                                </div>
                            </Grid>

                            <Grid item xs={3}>
                                <Input 
                                value={calcValues.tyt.turkce.dogru == 0 ? '' : calcValues.tyt.turkce.dogru}
                                key={"calcvalues_tyt_turkce_dogru"}
                                onChange={(e) => 
                                setCalcValues({...calcValues, tyt: {...calcValues.tyt, turkce: {...calcValues.tyt.turkce, dogru: Number(e.target.value)}}})}
                                type={"number"} style={{ width: "100%", textAlign: "center", fontSize: "small" }} />
                            </Grid>

                            <Grid item xs={3}>
                                <Input 
                                value={calcValues.tyt.turkce.yanlis == 0 ? '' : calcValues.tyt.turkce.yanlis}
                                key={"calcvalues_tyt_turkce_yanlis"}
                                onChange={(e) => 
                                setCalcValues({...calcValues, tyt: {...calcValues.tyt, turkce: {...calcValues.tyt.turkce, yanlis: Number(e.target.value)}}})}
                                type={"number"} style={{ width: "100%", textAlign: "center", fontSize: "small" }} />
                            </Grid>

                            <Grid item xs={3} style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                                <div className='netler-input' style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                                    {calcValues.tyt.turkce.max_toplam} Soru
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} item xs={12} alignItems="center">
                            <Grid item xs={3}>
                                <div style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                                    Sosyal Bilimler
                                </div>
                            </Grid>

                            <Grid item xs={3}>
                                <Input 
                                value={calcValues.tyt.sos.dogru == 0 ? '' : calcValues.tyt.sos.dogru}
                                key={"calcvalues_tyt_sos_dogru"}
                                onChange={(e) => 
                                setCalcValues({...calcValues, tyt: {...calcValues.tyt, sos: {...calcValues.tyt.sos, dogru: Number(e.target.value)}}})}
                                type={"number"} style={{ width: "100%", textAlign: "center", fontSize: "small" }} />
                            </Grid>

                            <Grid item xs={3}>
                                <Input 
                                value={calcValues.tyt.sos.yanlis == 0 ? '' : calcValues.tyt.sos.yanlis}
                                key={"calcvalues_tyt_sos_yanlis"}
                                onChange={(e) => 
                                setCalcValues({...calcValues, tyt: {...calcValues.tyt, sos: {...calcValues.tyt.sos, yanlis: Number(e.target.value)}}})}
                                type={"number"} style={{ width: "100%", textAlign: "center", fontSize: "small" }} />
                            </Grid>

                            <Grid item xs={3} style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                                <div className='netler-input' style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                                    {calcValues.tyt.sos.max_toplam} Soru
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} item xs={12} alignItems="center">
                            <Grid item xs={3}>
                                <div style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                                    Matematik
                                </div>
                            </Grid>

                            <Grid item xs={3}>
                                <Input 
                                value={calcValues.tyt.mat.dogru == 0 ? '' : calcValues.tyt.mat.dogru}
                                key={"calcvalues_tyt_mat_dogru"}
                                onChange={(e) => 
                                setCalcValues({...calcValues, tyt: {...calcValues.tyt, mat: {...calcValues.tyt.mat, dogru: Number(e.target.value)}}})}
                                type={"number"} style={{ width: "100%", textAlign: "center", fontSize: "small" }} />
                            </Grid>

                            <Grid item xs={3}>
                                <Input 
                                value={calcValues.tyt.mat.yanlis == 0 ? '' : calcValues.tyt.mat.yanlis}
                                key={"calcvalues_tyt_mat_yanlis"}
                                onChange={(e) => 
                                setCalcValues({...calcValues, tyt: {...calcValues.tyt, mat: {...calcValues.tyt.mat, yanlis: Number(e.target.value)}}})}
                                type={"number"} style={{ width: "100%", textAlign: "center", fontSize: "small" }} />
                            </Grid>

                            <Grid item xs={3} style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                                <div className='netler-input' style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                                    {calcValues.tyt.mat.max_toplam} Soru
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} item xs={12} alignItems="center">
                            <Grid item xs={3}>
                                <div style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                                    Fen Bilimleri
                                </div>
                            </Grid>

                            <Grid item xs={3}>
                                <Input 
                                value={calcValues.tyt.fen.dogru == 0 ? '' : calcValues.tyt.fen.dogru}
                                key={"calcvalues_tyt_fen_dogru"}
                                onChange={(e) => 
                                setCalcValues({...calcValues, tyt: {...calcValues.tyt, fen: {...calcValues.tyt.fen, dogru: Number(e.target.value)}}})}
                                type={"number"} style={{ width: "100%", textAlign: "center", fontSize: "small" }} />
                            </Grid>

                            <Grid item xs={3}>
                                <Input 
                                value={calcValues.tyt.fen.yanlis == 0 ? '' : calcValues.tyt.fen.yanlis}
                                key={"calcvalues_tyt_fen_yanlis"}
                                onChange={(e) => 
                                setCalcValues({...calcValues, tyt: {...calcValues.tyt, fen: {...calcValues.tyt.fen, yanlis: Number(e.target.value)}}})}
                                type={"number"} style={{ width: "100%", textAlign: "center", fontSize: "small" }} />
                            </Grid>

                            <Grid item xs={3} style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                                <div className='netler-input' style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                                    {calcValues.tyt.fen.max_toplam} Soru
                                </div>
                            </Grid>
                        </Grid>
                     
                    </Grid>
                </Grid>
                </> 
                : activeStep == 2 ? 
                <>
                <Grid sx={{ display: { xs: 'none', md: 'flex' } }} container spacing={1} marginTop={4} marginBottom={4}>
                    <Grid item xl={12} xs={3} container justifyContent={"center"} spacing={2}>
                        <Grid item 
                        
                        style={{ display: "flex", alignItems: "end", justifyContent: "end" }}>
                            <div style={{ width: 40, fontSize: "1rem" }} >
                                
                            </div>
                        </Grid>

                        <Grid item style={{ display: "flex", alignItems: "end", justifyContent: "end" }}>
                            <div style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                                Türk Dili ve Edebiyatı
                            </div>
                        </Grid>

                        <Grid item style={{ display: "flex", alignItems: "end", justifyContent: "end" }}>
                            <div className='col d-flex flex-column align-items-center justify-content-end' style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                                Tarih - 1
                            </div>
                        </Grid>

                        <Grid item style={{ display: "flex", alignItems: "end", justifyContent: "end" }}>
                            <div className='col d-flex flex-column align-items-center justify-content-end' style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                                Coğrafya - 1
                            </div>
                        </Grid>

                        
                        <Grid item style={{ display: "flex", alignItems: "end", justifyContent: "end" }}>
                            <div className='col d-flex flex-column align-items-center justify-content-end' style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                                Tarih - 2
                            </div>
                        </Grid>

                        <Grid item style={{ display: "flex", alignItems: "end", justifyContent: "end" }}>
                            <div className='col d-flex flex-column align-items-center justify-content-end' style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                                Coğrafya - 2
                            </div>
                        </Grid>

                        <Grid item style={{ display: "flex", alignItems: "end", justifyContent: "end" }}>
                            <div className='col d-flex flex-column align-items-center justify-content-end' style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                                Felsefe
                            </div>
                        </Grid>

                        <Grid item style={{ display: "flex", alignItems: "end", justifyContent: "end" }}>
                            <div className='col d-flex flex-column align-items-center justify-content-end' style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                                Din
                            </div>
                        </Grid>

                        <Grid item style={{ display: "flex", alignItems: "end", justifyContent: "end" }}>
                            <div className='col d-flex flex-column align-items-center justify-content-end' style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                                Matematik
                            </div>
                        </Grid>

            

                        <Grid item style={{ display: "flex", alignItems: "end", justifyContent: "end" }}>
                            <div className='col d-flex flex-column align-items-center justify-content-end' style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                                Fizik
                            </div>
                        </Grid>

                        <Grid item style={{ display: "flex", alignItems: "end", justifyContent: "end" }}>
                            <div className='col d-flex flex-column align-items-center justify-content-end' style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                                Kimya
                            </div>
                        </Grid>


                        <Grid item style={{ display: "flex", alignItems: "end", justifyContent: "end" }}>
                            <div className='col d-flex flex-column align-items-center justify-content-end' style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                                Biyoloji
                            </div>
                        </Grid>
                    </Grid>

                    <Grid item xl={12} xs={3} container justifyContent={"center"} spacing={2}>
                        <Grid item style={{ display: "flex", alignItems: "center" }}>
                            <span className="netler-input" style={{ width: 40, }}>
                                <b>
                                    Doğru:
                                </b>
                            </span>
                        </Grid>
                        <Grid item>
                            <Input 
                            value={calcValues.ayt.edebiyat.dogru == 0 ? '' : calcValues.ayt.edebiyat.dogru}
                            key={"calcvalues_ayt_dogru_edebiyat"}
                            onChange={(e) => 
                            setCalcValues({...calcValues, ayt: {...calcValues.ayt, edebiyat: {...calcValues.ayt.edebiyat, dogru: Number(e.target.value)}}})}
                            type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                        </Grid>

                        <Grid item>
                            <Input 
                            value={calcValues.ayt.tarih_1.dogru == 0 ? '' : calcValues.ayt.tarih_1.dogru}
                            key={"calcvalues_ayt_dogru_tarih_1"}
                            onChange={(e) => 
                                setCalcValues({...calcValues, ayt: {...calcValues.ayt, tarih_1: {...calcValues.ayt.tarih_1, dogru: Number(e.target.value)}}})}
                            type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                        </Grid>

                        <Grid item>
                            <Input 
                            value={calcValues.ayt.cografya_1.dogru == 0 ? '' : calcValues.ayt.cografya_1.dogru}
                            key={"calcvalues_ayt_dogru_cografya_1"}
                            onChange={(e) => 
                            setCalcValues({...calcValues, ayt: {...calcValues.ayt, cografya_1: {...calcValues.ayt.cografya_1, dogru: Number(e.target.value)}}})}
                            type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                        </Grid>

                        <Grid item>
                            <Input 
                            value={calcValues.ayt.tarih_2.dogru == 0 ? '' : calcValues.ayt.tarih_2.dogru}
                            key={"calcvalues_ayt_dogru_tarih_2"}
                            onChange={(e) => 
                            setCalcValues({...calcValues, ayt: {...calcValues.ayt, tarih_2: {...calcValues.ayt.tarih_2, dogru: Number(e.target.value)}}})}
                            type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                        </Grid>


                        <Grid item>
                            <Input 
                            value={calcValues.ayt.cografya_2.dogru == 0 ? '' : calcValues.ayt.cografya_2.dogru}
                            onChange={(e) => 
                            setCalcValues({...calcValues, ayt: {...calcValues.ayt, cografya_2: {...calcValues.ayt.cografya_2, dogru: Number(e.target.value)}}})}
                            type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                        </Grid>

                        <Grid item>
                            <Input 
                            value={calcValues.ayt.felsefe.dogru == 0 ? '' : calcValues.ayt.felsefe.dogru}
                            onChange={(e) => 
                            setCalcValues({...calcValues, ayt: {...calcValues.ayt, felsefe: {...calcValues.ayt.felsefe, dogru: Number(e.target.value)}}})}
                            type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                        </Grid>

                        <Grid item>
                            <Input 
                            value={calcValues.ayt.din.dogru == 0 ? '' : calcValues.ayt.din.dogru}
                            onChange={(e) => 
                            setCalcValues({...calcValues, ayt: {...calcValues.ayt, din: {...calcValues.ayt.din, dogru: Number(e.target.value)}}})}
                            type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                        </Grid>

                        <Grid item>
                            <Input 
                            value={calcValues.ayt.mat.dogru == 0 ? '' : calcValues.ayt.mat.dogru}
                            onChange={(e) => 
                            setCalcValues({...calcValues, ayt: {...calcValues.ayt, mat: {...calcValues.ayt.mat, dogru: Number(e.target.value)}}})}
                            type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                        </Grid>

                        <Grid item>
                            <Input 
                            value={calcValues.ayt.fizik.dogru == 0 ? '' : calcValues.ayt.fizik.dogru}
                            onChange={(e) => 
                            setCalcValues({...calcValues, ayt: {...calcValues.ayt, fizik: {...calcValues.ayt.fizik, dogru: Number(e.target.value)}}})}
                            type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                        </Grid>


                        <Grid item>
                            <Input 
                            value={calcValues.ayt.kimya.dogru == 0 ? '' : calcValues.ayt.kimya.dogru}
                            onChange={(e) => 
                            setCalcValues({...calcValues, ayt: {...calcValues.ayt, kimya: {...calcValues.ayt.kimya, dogru: Number(e.target.value)}}})}
                            type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                        </Grid>

                        <Grid item>
                            <Input 
                            value={calcValues.ayt.biyoloji.dogru == 0 ? '' : calcValues.ayt.biyoloji.dogru}
                            onChange={(e) => 
                            setCalcValues({...calcValues, ayt: {...calcValues.ayt, biyoloji: {...calcValues.ayt.biyoloji, dogru: Number(e.target.value)}}})}
                            type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                        </Grid>
                    </Grid>

                    <Grid item xl={12} xs={3}  container justifyContent={"center"} spacing={2}>
                        <Grid item style={{ display: "flex", alignItems: "center" }}>
                            <span className="netler-input" style={{ width: 40, }}>
                                <b>
                                    Yanlış:
                                </b>
                            </span>
                        </Grid>

                        <Grid item>
                            <Input 
                            value={calcValues.ayt.edebiyat.yanlis == 0 ? '' : calcValues.ayt.edebiyat.yanlis}
                            key={"calcvalues_ayt_yanlis_edebiyat"}
                            onChange={(e) => 
                            setCalcValues({...calcValues, ayt: {...calcValues.ayt, edebiyat: {...calcValues.ayt.edebiyat, yanlis: Number(e.target.value)}}})}
                            type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                        </Grid>

                        <Grid item>
                            <Input 
                            value={calcValues.ayt.tarih_1.yanlis == 0 ? '' : calcValues.ayt.tarih_1.yanlis}
                            key={"calcvalues_ayt_yanlis_tarih_1"}
                            onChange={(e) => 
                            setCalcValues({...calcValues, ayt: {...calcValues.ayt, tarih_1: {...calcValues.ayt.tarih_1, yanlis: Number(e.target.value)}}})}
                            type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                        </Grid>

                        <Grid item>
                            <Input 
                            value={calcValues.ayt.cografya_1.yanlis == 0 ? '' : calcValues.ayt.cografya_1.yanlis}
                            key={"calcvalues_ayt_yanlis_cografya_1"}
                            onChange={(e) => 
                            setCalcValues({...calcValues, ayt: {...calcValues.ayt, cografya_1: {...calcValues.ayt.cografya_1, yanlis: Number(e.target.value)}}})}
                            type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                        </Grid>

                        <Grid item>
                            <Input 
                            value={calcValues.ayt.tarih_2.yanlis == 0 ? '' : calcValues.ayt.tarih_2.yanlis}
                            key={"calcvalues_ayt_yanlis_tarih_2"}
                            onChange={(e) => 
                            setCalcValues({...calcValues, ayt: {...calcValues.ayt, tarih_2: {...calcValues.ayt.tarih_2, yanlis: Number(e.target.value)}}})}
                            type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                        </Grid>

                        <Grid item>
                            <Input 
                            value={calcValues.ayt.cografya_2.yanlis == 0 ? '' : calcValues.ayt.cografya_2.yanlis}
                            key={"calcvalues_ayt_yanlis_cografya_2"}
                            onChange={(e) => 
                            setCalcValues({...calcValues, ayt: {...calcValues.ayt, cografya_2: {...calcValues.ayt.cografya_2, yanlis: Number(e.target.value)}}})}
                            type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                        </Grid>

                        <Grid item>
                            <Input 
                            value={calcValues.ayt.felsefe.yanlis == 0 ? '' : calcValues.ayt.felsefe.yanlis}
                            onChange={(e) => 
                            setCalcValues({...calcValues, ayt: {...calcValues.ayt, felsefe: {...calcValues.ayt.felsefe, yanlis: Number(e.target.value)}}})}
                            type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                        </Grid>

                        <Grid item>
                            <Input 
                            value={calcValues.ayt.din.yanlis == 0 ? '' : calcValues.ayt.din.yanlis}
                            onChange={(e) => 
                            setCalcValues({...calcValues, ayt: {...calcValues.ayt, din: {...calcValues.ayt.din, yanlis: Number(e.target.value)}}})}
                            type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                        </Grid>

                        <Grid item>
                            <Input 
                            value={calcValues.ayt.mat.yanlis == 0 ? '' : calcValues.ayt.mat.yanlis}
                            onChange={(e) => 
                            setCalcValues({...calcValues, ayt: {...calcValues.ayt, mat: {...calcValues.ayt.mat, yanlis: Number(e.target.value)}}})}
                            type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                        </Grid>

                        <Grid item>
                            <Input 
                            value={calcValues.ayt.fizik.yanlis == 0 ? '' : calcValues.ayt.fizik.yanlis}
                            onChange={(e) => 
                            setCalcValues({...calcValues, ayt: {...calcValues.ayt, fizik: {...calcValues.ayt.fizik, yanlis: Number(e.target.value)}}})}
                            type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                        </Grid>

                        <Grid item>
                            <Input 
                            value={calcValues.ayt.kimya.yanlis == 0 ? '' : calcValues.ayt.kimya.yanlis}
                            onChange={(e) => 
                            setCalcValues({...calcValues, ayt: {...calcValues.ayt, kimya: {...calcValues.ayt.kimya, yanlis: Number(e.target.value)}}})}
                            type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                        </Grid>

                        <Grid item>
                            <Input 
                            value={calcValues.ayt.biyoloji.yanlis == 0 ? '' : calcValues.ayt.biyoloji.yanlis}
                            onChange={(e) => 
                            setCalcValues({...calcValues, ayt: {...calcValues.ayt, biyoloji: {...calcValues.ayt.biyoloji, yanlis: Number(e.target.value)}}})}
                            type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                        </Grid>

                    </Grid>

                    <Grid item xl={12} xs={3} container justifyContent={"center"} spacing={2}>
                        <Grid item style={{ display: "flex", alignItems: "center" }}>
                            <span className="netler-input" style={{ width: 40, }}>
                            </span>
                        </Grid>
                        <Grid item style={{ display: "flex", alignItems: "end", justifyContent: "end" }}>
                            <div className='netler-input' style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                                {calcValues.ayt.edebiyat.max_toplam} Soru
                            </div>
                        </Grid>

                        <Grid item style={{ display: "flex", alignItems: "end", justifyContent: "end" }}>
                            <div className='netler-input' style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                            {calcValues.ayt.tarih_1.max_toplam} Soru
                            </div>
                        </Grid>

                        <Grid item style={{ display: "flex", alignItems: "end", justifyContent: "end" }}>
                            <div className='netler-input' style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                            {calcValues.ayt.cografya_1.max_toplam} Soru
                            </div>
                        </Grid>

                        <Grid item style={{ display: "flex", alignItems: "end", justifyContent: "end" }}>
                            <div className='netler-input' style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                            {calcValues.ayt.tarih_2.max_toplam} Soru
                            </div>
                        </Grid>

                        <Grid item style={{ display: "flex", alignItems: "end", justifyContent: "end" }}>
                            <div className='netler-input' style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                            {calcValues.ayt.cografya_2.max_toplam} Soru
                            </div>
                        </Grid>

                        <Grid item style={{ display: "flex", alignItems: "end", justifyContent: "end" }}>
                            <div className='netler-input' style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                            {calcValues.ayt.felsefe.max_toplam} Soru
                            </div>
                        </Grid>

                        <Grid item style={{ display: "flex", alignItems: "end", justifyContent: "end" }}>
                            <div className='netler-input' style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                            {calcValues.ayt.din.max_toplam} Soru
                            </div>
                        </Grid>

                        <Grid item style={{ display: "flex", alignItems: "end", justifyContent: "end" }}>
                            <div className='netler-input' style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                            {calcValues.ayt.mat.max_toplam} Soru
                            </div>
                        </Grid>

                        <Grid item style={{ display: "flex", alignItems: "end", justifyContent: "end" }}>
                            <div className='netler-input' style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                            {calcValues.ayt.fizik.max_toplam} Soru
                            </div>
                        </Grid>

                        <Grid item style={{ display: "flex", alignItems: "end", justifyContent: "end" }}>
                            <div className='netler-input' style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                            {calcValues.ayt.kimya.max_toplam} Soru
                            </div>
                        </Grid>

                        <Grid item style={{ display: "flex", alignItems: "end", justifyContent: "end" }}>
                            <div className='netler-input' style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                            {calcValues.ayt.biyoloji.max_toplam} Soru
                            </div>
                        </Grid>

                        
                    </Grid>
                </Grid>

                <Grid sx={{ display: { xs: 'flex', md: 'none' } }} container spacing={1} marginTop={4} marginBottom={4}>
                    <Grid item xl={12} direction="row" container justifyContent={"center"} spacing={2}>
                    <Grid container spacing={2} item xs={12} alignItems="center">
                            <Grid item xs={3}>
                                <div style={{ width: 65, textAlign: "left", fontSize: "0.7rem" }} >
                                    
                                </div>
                            </Grid>

                            <Grid item xs={3} display="flex" justifyContent={"center"}>
                                <div className="netler-input">
                                    <b>
                                    Doğru:
                                    </b>
                                </div>
                            </Grid>

                            <Grid item xs={3} display="flex" justifyContent={"center"}>
                                <div className="netler-input">
                                    <b>
                                    Yanlış:
                                    </b>
                                </div>
                            </Grid>

                            <Grid item xs={3} style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                                <div className='netler-input' style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                                    160 Soru
                                </div>
                            </Grid>
                        </Grid>
                        
                        <Grid container spacing={2} item xs={12} alignItems="center">
                            <Grid item xs={3}>
                                <div style={{ width: 65, textAlign: "left", fontSize: "0.7rem" }} >
                                    Türk Dili ve Edebiyatı
                                </div>
                            </Grid>

                            <Grid item xs={3}>
                                <Input 
                                onChange={(e) => 
                                setCalcValues({...calcValues, ayt: {...calcValues.ayt, edebiyat: {...calcValues.ayt.edebiyat, dogru: Number(e.target.value)}}})}
                                type={"number"} style={{ width: "100%", textAlign: "center", fontSize: "small" }} />
                            </Grid>

                            <Grid item xs={3}>
                                <Input 
                                onChange={(e) => 
                                setCalcValues({...calcValues, ayt: {...calcValues.ayt, edebiyat: {...calcValues.ayt.edebiyat, yanlis: Number(e.target.value)}}})}
                                type={"number"} style={{ width: "100%", textAlign: "center", fontSize: "small" }} />
                            </Grid>

                            <Grid item xs={3} style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                                <div className='netler-input' style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                                    {calcValues.ayt.edebiyat.max_toplam} Soru
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} item xs={12} alignItems="center">
                            <Grid item xs={3}>
                                <div style={{ width: 65, textAlign: "left", fontSize: "0.7rem" }} >
                                    Tarih - 1
                                </div>
                            </Grid>

                            <Grid item xs={3}>
                                <Input 
                                onChange={(e) => 
                                setCalcValues({...calcValues, ayt: {...calcValues.ayt, tarih_1: {...calcValues.ayt.tarih_1, dogru: Number(e.target.value)}}})}
                                type={"number"} style={{ width: "100%", textAlign: "center", fontSize: "small" }} />
                            </Grid>

                            <Grid item xs={3}>
                                <Input 
                                onChange={(e) => 
                                setCalcValues({...calcValues, ayt: {...calcValues.ayt, tarih_1: {...calcValues.ayt.tarih_1, yanlis: Number(e.target.value)}}})}
                                type={"number"} style={{ width: "100%", textAlign: "center", fontSize: "small" }} />
                            </Grid>

                            <Grid item xs={3} style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                                <div className='netler-input' style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                                    {calcValues.ayt.tarih_1.max_toplam} Soru
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} item xs={12} alignItems="center">
                            <Grid item xs={3}>
                                <div style={{ width: 65, textAlign: "left", fontSize: "0.7rem" }} >
                                    Coğr. - 1
                                </div>
                            </Grid>

                            <Grid item xs={3}>
                                <Input 
                                onChange={(e) => 
                                setCalcValues({...calcValues, ayt: {...calcValues.ayt, cografya_1: {...calcValues.ayt.cografya_1, dogru: Number(e.target.value)}}})}
                                type={"number"} style={{ width: "100%", textAlign: "center", fontSize: "small" }} />
                            </Grid>

                            <Grid item xs={3}>
                                <Input 
                                onChange={(e) => 
                                setCalcValues({...calcValues, ayt: {...calcValues.ayt, cografya_1: {...calcValues.ayt.cografya_1, yanlis: Number(e.target.value)}}})}
                                type={"number"} style={{ width: "100%", textAlign: "center", fontSize: "small" }} />
                            </Grid>

                            <Grid item xs={3} style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                                <div className='netler-input' style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                                {calcValues.ayt.cografya_1.max_toplam} Soru
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} item xs={12} alignItems="center">
                            <Grid item xs={3}>
                                <div style={{ width: 65, textAlign: "left", fontSize: "0.7rem" }} >
                                    Tarih - 2
                                </div>
                            </Grid>

                            <Grid item xs={3}>
                                <Input 
                                onChange={(e) => 
                                setCalcValues({...calcValues, ayt: {...calcValues.ayt, tarih_2: {...calcValues.ayt.tarih_2, dogru: Number(e.target.value)}}})}
                                type={"number"} style={{ width: "100%", textAlign: "center", fontSize: "small" }} />
                            </Grid>

                            <Grid item xs={3}>
                                <Input 
                                onChange={(e) => 
                                setCalcValues({...calcValues, ayt: {...calcValues.ayt, tarih_2: {...calcValues.ayt.tarih_2, yanlis: Number(e.target.value)}}})}
                                type={"number"} style={{ width: "100%", textAlign: "center", fontSize: "small" }} />
                            </Grid>

                            <Grid item xs={3} style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                                <div className='netler-input' style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                                {calcValues.ayt.tarih_2.max_toplam} Soru
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} item xs={12} alignItems="center">
                            <Grid item xs={3}>
                                <div style={{ width: 65, textAlign: "left", fontSize: "0.7rem" }} >
                                    Coğr. - 2
                                </div>
                            </Grid>

                            <Grid item xs={3}>
                                <Input 
                                onChange={(e) => 
                                setCalcValues({...calcValues, ayt: {...calcValues.ayt, cografya_2: {...calcValues.ayt.cografya_2, dogru: Number(e.target.value)}}})}
                                type={"number"} style={{ width: "100%", textAlign: "center", fontSize: "small" }} />
                            </Grid>

                            <Grid item xs={3}>
                                <Input 
                                onChange={(e) => 
                                setCalcValues({...calcValues, ayt: {...calcValues.ayt, cografya_2: {...calcValues.ayt.cografya_2, yanlis: Number(e.target.value)}}})}
                                type={"number"} style={{ width: "100%", textAlign: "center", fontSize: "small" }} />
                            </Grid>

                            <Grid item xs={3} style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                                <div className='netler-input' style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                                {calcValues.ayt.cografya_2.max_toplam} Soru
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} item xs={12} alignItems="center">
                            <Grid item xs={3}>
                                <div style={{ width: 65, textAlign: "left", fontSize: "0.7rem" }} >
                                    Felsefe
                                </div>
                            </Grid>

                            <Grid item xs={3}>
                                <Input 
                                onChange={(e) => 
                                setCalcValues({...calcValues, ayt: {...calcValues.ayt, felsefe: {...calcValues.ayt.felsefe, dogru: Number(e.target.value)}}})}
                                type={"number"} style={{ width: "100%", textAlign: "center", fontSize: "small" }} />
                            </Grid>

                            <Grid item xs={3}>
                                <Input 
                                onChange={(e) => 
                                setCalcValues({...calcValues, ayt: {...calcValues.ayt, felsefe: {...calcValues.ayt.felsefe, yanlis: Number(e.target.value)}}})}
                                type={"number"} style={{ width: "100%", textAlign: "center", fontSize: "small" }} />
                            </Grid>

                            <Grid item xs={3} style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                                <div className='netler-input' style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                                {calcValues.ayt.felsefe.max_toplam} Soru
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} item xs={12} alignItems="center">
                            <Grid item xs={3}>
                                <div style={{ width: 65, textAlign: "left", fontSize: "0.7rem" }} >
                                    Din Kültürü
                                </div>
                            </Grid>

                            <Grid item xs={3}>
                                <Input 
                                onChange={(e) => 
                                setCalcValues({...calcValues, ayt: {...calcValues.ayt, din: {...calcValues.ayt.din, dogru: Number(e.target.value)}}})}
                                type={"number"} style={{ width: "100%", textAlign: "center", fontSize: "small" }} />
                            </Grid>

                            <Grid item xs={3}>
                                <Input 
                                onChange={(e) => 
                                setCalcValues({...calcValues, ayt: {...calcValues.ayt, din: {...calcValues.ayt.din, yanlis: Number(e.target.value)}}})}
                                type={"number"} style={{ width: "100%", textAlign: "left", fontSize: "small" }} />
                            </Grid>

                            <Grid item xs={3} style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                                <div className='netler-input' style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                                {calcValues.ayt.din.max_toplam} Soru
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} item xs={12} alignItems="center">
                            <Grid item xs={3}>
                                <div style={{ width: 65, textAlign: "left", fontSize: "0.7rem" }} >
                                    Matematik
                                </div>
                            </Grid>

                            <Grid item xs={3}>
                                <Input 
                                onChange={(e) => 
                                setCalcValues({...calcValues, ayt: {...calcValues.ayt, mat: {...calcValues.ayt.mat, dogru: Number(e.target.value)}}})}
                                type={"number"} style={{ width: "100%", textAlign: "center", fontSize: "small" }} />
                            </Grid>

                            <Grid item xs={3}>
                                <Input 
                                onChange={(e) => 
                                setCalcValues({...calcValues, ayt: {...calcValues.ayt, mat: {...calcValues.ayt.mat, yanlis: Number(e.target.value)}}})}
                                type={"number"} style={{ width: "100%", textAlign: "center", fontSize: "small" }} />
                            </Grid>

                            <Grid item xs={3} style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                                <div className='netler-input' style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                                {calcValues.ayt.mat.max_toplam} Soru
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} item xs={12} alignItems="center">
                            <Grid item xs={3}>
                                <div style={{ width: 65, textAlign: "left", fontSize: "0.7rem" }} >
                                    Fizik
                                </div>
                            </Grid>

                            <Grid item xs={3}>
                                <Input 
                                onChange={(e) => 
                                setCalcValues({...calcValues, ayt: {...calcValues.ayt, fizik: {...calcValues.ayt.fizik, dogru: Number(e.target.value)}}})}
                                type={"number"} style={{ width: "100%", textAlign: "left", fontSize: "small" }} />
                            </Grid>

                            <Grid item xs={3}>
                                <Input 
                                onChange={(e) => 
                                setCalcValues({...calcValues, ayt: {...calcValues.ayt, fizik: {...calcValues.ayt.fizik, yanlis: Number(e.target.value)}}})}
                                type={"number"} style={{ width: "100%", textAlign: "center", fontSize: "small" }} />
                            </Grid>

                            <Grid item xs={3} style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                                <div className='netler-input' style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                                {calcValues.ayt.fizik.max_toplam} Soru
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} item xs={12} alignItems="center">
                            <Grid item xs={3}>
                                <div style={{ width: 65, textAlign: "left", fontSize: "0.7rem" }} >
                                    Kimya
                                </div>
                            </Grid>

                            <Grid item xs={3}>
                                <Input 
                                onChange={(e) => 
                                setCalcValues({...calcValues, ayt: {...calcValues.ayt, kimya: {...calcValues.ayt.kimya, dogru: Number(e.target.value)}}})}
                                type={"number"} style={{ width: "100%", textAlign: "center", fontSize: "small" }} />
                            </Grid>

                            <Grid item xs={3}>
                                <Input 
                                onChange={(e) => 
                                setCalcValues({...calcValues, ayt: {...calcValues.ayt, kimya: {...calcValues.ayt.kimya, yanlis: Number(e.target.value)}}})}
                                type={"number"} style={{ width: "100%", textAlign: "center", fontSize: "small" }} />
                            </Grid>

                            <Grid item xs={3} style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                                <div className='netler-input' style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                                {calcValues.ayt.kimya.max_toplam} Soru
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} item xs={12} alignItems="center">
                            <Grid item xs={3}>
                                <div style={{ width: 65, textAlign: "left", fontSize: "0.7rem" }} >
                                    Biyoloji
                                </div>
                            </Grid>

                            <Grid item xs={3}>
                                <Input 
                                onChange={(e) => 
                                setCalcValues({...calcValues, ayt: {...calcValues.ayt, biyoloji: {...calcValues.ayt.biyoloji, dogru: Number(e.target.value)}}})}
                                type={"number"} style={{ width: "100%", textAlign: "center", fontSize: "small" }} />
                            </Grid>

                            <Grid item xs={3}>
                                <Input 
                                onChange={(e) => 
                                setCalcValues({...calcValues, ayt: {...calcValues.ayt, biyoloji: {...calcValues.ayt.biyoloji, yanlis: Number(e.target.value)}}})}
                                type={"number"} style={{ width: "100%", textAlign: "center", fontSize: "small" }} />
                            </Grid>

                            <Grid item xs={3} style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                                <div className='netler-input' style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                                {calcValues.ayt.biyoloji.max_toplam} Soru
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                </> : activeStep == 3 &&
                <>
                <Grid direction={"row"} container spacing={1} marginTop={4} marginBottom={4}>
                    <Grid item container justifyContent={"center"} spacing={2}>
                        <Grid item style={{ display: "flex", alignItems: "end", justifyContent: "end" }}>
                            <div style={{ width: 40, fontSize: "1rem" }} >
                                
                            </div>
                        </Grid>

                        <Grid item style={{ display: "flex", alignItems: "end", justifyContent: "end" }}>
                            <div style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                                Yabancı Dil
                            </div>
                        </Grid>

                    </Grid>

                    <Grid item container justifyContent={"center"} spacing={2}>
                        <Grid item style={{ display: "flex", alignItems: "center" }}>
                            <span className="netler-input" style={{ width: 40, }}>
                                <b>
                                    Doğru:
                                </b>
                            </span>
                        </Grid>
                        <Grid item>
                            <Input 
                            value={calcValues.dil.dogru == 0 ? '' : calcValues.dil.dogru}
                            key={"calcvalues_dil_dogru"}
                            onChange={(e) => 
                                setCalcValues({...calcValues, dil: {...calcValues.dil, dogru: Number(e.target.value)}})}
                            type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                        </Grid>

                    </Grid>

                    <Grid item container justifyContent={"center"} spacing={2}>
                        <Grid item style={{ display: "flex", alignItems: "center" }}>
                            <span className="netler-input" style={{ width: 40, }}>
                                <b>
                                    Yanlış:
                                </b>
                            </span>
                        </Grid>

                        <Grid item>
                            <Input 
                            value={calcValues.dil.yanlis == 0 ? '' : calcValues.dil.yanlis}
                            key={"calcvalues_dil_yanlis"}
                            onChange={(e) => 
                                setCalcValues({...calcValues, dil: {...calcValues.dil, yanlis: Number(e.target.value)}})}
                            type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                        </Grid>

                    </Grid>

                    <Grid item container justifyContent={"center"} spacing={2}>
                        <Grid item style={{ display: "flex", alignItems: "center" }}>
                            <span className="netler-input" style={{ width: 40, }}>
                            </span>
                        </Grid>
                        <Grid item style={{ display: "flex", alignItems: "end", justifyContent: "end" }}>
                            <div className='netler-input' style={{ width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                                {calcValues.dil.max_toplam} Soru
                            </div>
                        </Grid>

                       

                        
                    </Grid>
                </Grid>
                </>}
            </div>              
        
            <div className='explain mt-2 d-flex flex-column align-items-center'>
            
                
        
                {activeStep == steps.length - 1 ?
                <>
                <span className='detail'>
                <b>hesapla</b> butonuna basarak puanını hesaplayabilirsin.
                </span>
                <div className="d-flex">
                    <Button disabled={activeStep==0} onClick={() => setActiveStep(activeStep - 1)} style={{ marginRight: 16, width: 200, backgroundColor: 'rgb(243, 212, 183)' }} className='mt-4 sonuc-button'>
                        Geri
                    </Button>

                    <Button onClick={() => Calculate()} style={{ width: 200, backgroundColor: 'rgb(243, 212, 183)' }} className='mt-4 sonuc-button'>
                        Hesapla
                    </Button>
                </div>
                </> : 
                <>
                
                <Grid container justifyContent={"center"} spacing={3}>
                    <Grid item xl={12} container justifyContent={"center"} textAlign="center">
                        <span className='detail'>
                        Bilgilerini girdikten sonra <b>ileri</b> butonuna basarak ilerleyebilirsin.
                        </span>
                    </Grid>
                    <Grid item xl={3} xs={12}>
                    <Button disabled={activeStep==0} onClick={() => setActiveStep(activeStep - 1)} style={{ width: "100%", backgroundColor: 'rgb(243, 212, 183)' }} className='sonuc-button'>
                        Geri
                    </Button>
                    </Grid>

                    <Grid item xl={3} xs={12}>
                    <Button onClick={() => ChangeStep(activeStep + 1)} style={{ width: "100%", backgroundColor: 'rgb(243, 212, 183)' }} className='sonuc-button'>
                        İleri
                    </Button>
                    </Grid>
                </Grid>
                
                </>}
            </div>
            </Route>
        </>
    )
}

export default YksCalculate;

/*
<div className='col-12 d-flex flex-column' >
                        <div className="d-flex align-items-center" >
                            <span className="d-flex justify-content-center align-items-end" style={{ width: 40}}>
                                AYT
                            </span>
                            <div className="row" style={{ marginLeft: 15 }}>
                                <div className='col d-flex flex-column align-items-center justify-content-end' style={{ marginLeft: 10, width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                                Türk Dili ve Edebiyatı
                                </div>
                                
                                <div className='col d-flex flex-column align-items-center justify-content-end'style={{ marginLeft: 25, width: 65, textAlign: "center", fontSize: "0.7rem" }} >
                                Tarih - 1
                                </div>

                                <div className='col d-flex flex-column align-items-center justify-content-end' style={{ marginLeft: 25, width: 65, textAlign: "center", fontSize: "0.7rem" }}>
                                Coğrafya - 1
                                </div>

                                <div className='col d-flex flex-column align-items-center justify-content-end' style={{ marginLeft: 25, width: 65, textAlign: "center", fontSize: "0.7rem" }}>
                                Tarih - 2
                                </div>

                                <div className='col d-flex flex-column align-items-center justify-content-end' style={{ marginLeft: 25, width: 65, textAlign: "center", fontSize: "0.7rem" }}>
                                Coğrafya - 2
                                </div>

                                <div className='col d-flex flex-column align-items-center justify-content-end' style={{ marginLeft: 20, width: 65, textAlign: "center", fontSize: "0.7rem" }}>
                                Felsefe 
                                </div>

                                <div className='col d-flex flex-column align-items-center justify-content-end' style={{ marginLeft: 25, width: 65, textAlign: "center", fontSize: "0.7rem" }}>
                                Din 
                                </div>

                                <div className='col d-flex flex-column align-items-center justify-content-end' style={{ marginLeft: 25, width: 65, textAlign: "center", fontSize: "0.7rem" }}>
                                Matematik 
                                </div>

                                <div className='col d-flex flex-column align-items-center justify-content-end' style={{ marginLeft: 22, width: 65, textAlign: "center", fontSize: "0.7rem" }}>
                                Fizik
                                </div>
                                
                                <div className='col d-flex flex-column align-items-center justify-content-end' style={{ marginLeft: 25, width: 65, textAlign: "center", fontSize: "0.7rem" }}>
                                Kimya 
                                </div>

                                <div className='col d-flex flex-column align-items-center justify-content-end' style={{ marginLeft: 25, width: 65, textAlign: "center", fontSize: "0.7rem" }}>
                                Biyoloji 
                                </div>
                            
                            </div>
                        </div>
                
                        <div className="d-flex align-items-center" style={{ width: "100%" }}>
                            <span className="netler-input" style={{ width: 40}}>
                                <b>
                                Doğru : 
                                </b>
                            </span>
                            <div className="row" style={{ marginLeft: 15 }}>
                                <div className='col d-flex flex-column align-items-center' >
                                    <Input 
                                    onChange={(e) => 
                                        setCalcValues({...calcValues, ayt: {...calcValues.ayt, edebiyat: {...calcValues.ayt.edebiyat, dogru: Number(e.target.value)}}})}
                                    type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                                    <div className="netler-input">
                                    
                                    </div>
                                </div>
                                
                                <div className='col d-flex flex-column align-items-center' >
                                    <Input 
                                    onChange={(e) => 
                                        setCalcValues({...calcValues, ayt: {...calcValues.ayt, tarih_1: {...calcValues.ayt.tarih_1, dogru: Number(e.target.value)}}})}
                                    type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                                    <div className="netler-input">
                                    
                                    </div>
                                </div>

                                <div className='col d-flex flex-column align-items-center' >
                                    <Input 
                                    onChange={(e) => 
                                        setCalcValues({...calcValues, ayt: {...calcValues.ayt, cografya_1: {...calcValues.ayt.cografya_1, dogru: Number(e.target.value)}}})}
                                    type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                                    <div className="netler-input">
                                    
                                    </div>
                                </div>

                                <div className='col d-flex flex-column align-items-center' >
                                    <Input 
                                    onChange={(e) => 
                                        setCalcValues({...calcValues, ayt: {...calcValues.ayt, tarih_2: {...calcValues.ayt.tarih_2, dogru: Number(e.target.value)}}})}
                                    type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                                    <div className="netler-input">
                                    </div>
                                </div>

                                <div className='col d-flex flex-column align-items-center' >
                                    <Input 
                                    onChange={(e) => 
                                        setCalcValues({...calcValues, ayt: {...calcValues.ayt, cografya_2: {...calcValues.ayt.cografya_2, dogru: Number(e.target.value)}}})}
                                    type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                                    <div className="netler-input">
                                    </div>
                                </div>

                                <div className='col d-flex flex-column align-items-center' >
                                    <Input 
                                    onChange={(e) => 
                                        setCalcValues({...calcValues, ayt: {...calcValues.ayt, felsefe: {...calcValues.ayt.felsefe, dogru: Number(e.target.value)}}})}
                                    type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                                    <div className="netler-input">
                                    </div>
                                </div>

                                <div className='col d-flex flex-column align-items-center' >
                                    <Input 
                                    onChange={(e) => 
                                        setCalcValues({...calcValues, ayt: {...calcValues.ayt, din: {...calcValues.ayt.din, dogru: Number(e.target.value)}}})}
                                    type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                                    <div className="netler-input">
                                    </div>
                                </div>
                                <div className='col d-flex flex-column align-items-center' >
                                    <Input 
                                    onChange={(e) => 
                                        setCalcValues({...calcValues, ayt: {...calcValues.ayt, mat: {...calcValues.ayt.mat, dogru: Number(e.target.value)}}})}
                                    type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                                    <div className="netler-input">
                                    </div>
                                </div>

                                <div className='col d-flex flex-column align-items-center' >
                                    <Input 
                                    onChange={(e) => 
                                        setCalcValues({...calcValues, ayt: {...calcValues.ayt, fizik: {...calcValues.ayt.fizik, dogru: Number(e.target.value)}}})}
                                    type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                                    <div className="netler-input">
                                    </div>
                                </div>

                                <div className='col d-flex flex-column align-items-center' >
                                    <Input 
                                    onChange={(e) => 
                                        setCalcValues({...calcValues, ayt: {...calcValues.ayt, kimya: {...calcValues.ayt.kimya, dogru: Number(e.target.value)}}})}
                                    type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                                    <div className="netler-input">
                                    </div>
                                </div>

                                <div className='col d-flex flex-column align-items-center' >
                                    <Input 
                                    onChange={(e) => 
                                        setCalcValues({...calcValues, ayt: {...calcValues.ayt, biyoloji: {...calcValues.ayt.biyoloji, dogru: Number(e.target.value)}}})}
                                    type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                                    <div className="netler-input">
                                    </div>
                                </div>
                                
                            </div>
                        </div>

                        <div className="d-flex align-items-center" style={{ width: "100%" }}>
                        <span className="netler-input" style={{ width: 40}}>
                                <b>
                                Yanlış :
                                </b>
                            </span>
                            <div className="row" style={{ marginLeft: 15 }}>
                                <div className='col d-flex flex-column align-items-center' >
                                    <Input 
                                    onChange={(e) => 
                                        setCalcValues({...calcValues, ayt: {...calcValues.ayt, edebiyat: {...calcValues.ayt.edebiyat, yanlis: Number(e.target.value)}}})}
                                    type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                                    <div className="netler-input">
                                        {calcValues.ayt.edebiyat.max_toplam} Soru
                                    </div>
                                </div>
                                
                                <div className='col d-flex flex-column align-items-center' >
                                    <Input 
                                    onChange={(e) => 
                                        setCalcValues({...calcValues, ayt: {...calcValues.ayt, tarih_1: {...calcValues.ayt.tarih_1, yanlis: Number(e.target.value)}}})}
                                    type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                                    <div className="netler-input">
                                    {calcValues.ayt.tarih_1.max_toplam} Soru
                                    </div>
                                </div>

                                <div className='col d-flex flex-column align-items-center' >
                                    <Input 
                                    onChange={(e) => 
                                        setCalcValues({...calcValues, ayt: {...calcValues.ayt, cografya_1: {...calcValues.ayt.cografya_1, yanlis: Number(e.target.value)}}})}
                                    type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                                    <div className="netler-input">
                                    {calcValues.ayt.cografya_1.max_toplam} Soru
                                    </div>
                                </div>

                                <div className='col d-flex flex-column align-items-center' >
                                    <Input 
                                    onChange={(e) => 
                                        setCalcValues({...calcValues, ayt: {...calcValues.ayt, tarih_2: {...calcValues.ayt.tarih_2, yanlis: Number(e.target.value)}}})}
                                    type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                                    <div className="netler-input">
                                    {calcValues.ayt.tarih_2.max_toplam} Soru
                                    </div>
                                </div>

                                <div className='col d-flex flex-column align-items-center' >
                                    <Input 
                                    onChange={(e) => 
                                        setCalcValues({...calcValues, ayt: {...calcValues.ayt, cografya_2: {...calcValues.ayt.cografya_2, yanlis: Number(e.target.value)}}})}
                                    type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                                    <div className="netler-input">
                                    {calcValues.ayt.cografya_2.max_toplam} Soru
                                    </div>
                                </div>

                                <div className='col d-flex flex-column align-items-center' >
                                    <Input 
                                    onChange={(e) => 
                                        setCalcValues({...calcValues, ayt: {...calcValues.ayt, felsefe: {...calcValues.ayt.felsefe, yanlis: Number(e.target.value)}}})}
                                    type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                                    <div className="netler-input">
                                    {calcValues.ayt.felsefe.max_toplam} Soru
                                    </div>
                                </div>

                                <div className='col d-flex flex-column align-items-center' >
                                    <Input 
                                    onChange={(e) => 
                                        setCalcValues({...calcValues, ayt: {...calcValues.ayt, din: {...calcValues.ayt.din, yanlis: Number(e.target.value)}}})}
                                    type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                                    <div className="netler-input">
                                    {calcValues.ayt.din.max_toplam} Soru
                                    </div>
                                </div>
                                

                                <div className='col d-flex flex-column align-items-center' >
                                    <Input 
                                    onChange={(e) => 
                                        setCalcValues({...calcValues, ayt: {...calcValues.ayt, mat: {...calcValues.ayt.mat, yanlis: Number(e.target.value)}}})}
                                    type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                                    <div className="netler-input">
                                    {calcValues.ayt.mat.max_toplam} Soru
                                    </div>
                                </div>

                                <div className='col d-flex flex-column align-items-center' >
                                    <Input 
                                    onChange={(e) => 
                                        setCalcValues({...calcValues, ayt: {...calcValues.ayt, fizik: {...calcValues.ayt.fizik, yanlis: Number(e.target.value)}}})}
                                    type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                                    <div className="netler-input">
                                    {calcValues.ayt.fizik.max_toplam} Soru
                                    </div>
                                </div>

                                <div className='col d-flex flex-column align-items-center' >
                                    <Input 
                                    onChange={(e) => 
                                        setCalcValues({...calcValues, ayt: {...calcValues.ayt, kimya: {...calcValues.ayt.kimya, yanlis: Number(e.target.value)}}})}
                                    type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                                    <div className="netler-input">
                                    {calcValues.ayt.kimya.max_toplam} Soru
                                    </div>
                                </div>

                                <div className='col d-flex flex-column align-items-center' >
                                    <Input 
                                    onChange={(e) => 
                                        setCalcValues({...calcValues, ayt: {...calcValues.ayt, biyoloji: {...calcValues.ayt.biyoloji, yanlis: Number(e.target.value)}}})}
                                    type={"number"} className='mt-2' style={{ width: 65, textAlign: "center", fontSize: "small" }} />
                                    <div className="netler-input">
                                    {calcValues.ayt.biyoloji.max_toplam} Soru
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
*/