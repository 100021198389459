import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { Route, useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { Button, CloseButton, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Pagination, PaginationItem, PaginationLink, Table } from 'reactstrap';
import Loader from "./Loader";
import PaginatedItems from "./Paginate";
import { AiFillHome, AiFillFilePdf } from "react-icons/ai";
import { TiExport } from "react-icons/ti"
import LgsCalculate from "./LgsCalculate";
import { Box, Tooltip } from "@mui/material";
import biltes_logo from "../Assets/biltes_logo.jpeg";
import MakeExport from "../utils/Export";


function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

interface props {
  isLoading: boolean
}

function Lgs(props: props) {

  const [selectedSchools, setSelectedSchools] = useState([])
  const [tableLoading, setTableLoading] = useState(true)

  const [Schools, SetSchools] = useState([]);
  const [PaginationData, setPagination] = useState({
    prev_index: 0,
    next_index: 2,
    current_index: 1,
    is_have_next: false,
    is_have_previous: false,
    last_page: 10
  });

  const [state, setState] = useState({
    lgs_puani: 0,
    yuzdelik_dilim: 0,
    ogrenci_adi: 'Unnamed',
    fen: 0,
    mat: 0,
    turkce: 0,
    din: 0,
    ingilizce: 0,
    inkilap: 0,
    year: 2021,
    city: 'İstanbul',
    school_type: 'hepsi'
  })

  const [modalState, setModalState] = useState({
    state: false,
    item: {
      "school_name": "school_details.SchoolName",
      "school_type": "school_details.SchoolType",
      "school_adress": "school_details.SchoolAdress",
      "school_web_adress": "school_details.SchoolWebAdress",
      "school_phone_number": "school_details.Phone",
      "school_fax_number": "school_details.Fax",
      "school_district": "school_details.CityDistrict/school_details.CityName"
    }
  });



  const query = useQuery();
  const location = useLocation();
  const history = useHistory();




  function SetData() {
    if (location.pathname.includes("yks")) return
    setTableLoading(true)

    var path = GetPage();
    //37.230.104.39
    //https://tercihrobotu.testprojesi.com
    axios.get(`https://tercihrobotu.testprojesi.com/api/get?${path}`)
      .then((res) => {
        SetSchools(res.data.okullar)
        setPagination(res.data.pagination)

        setState({
          ...state,
          lgs_puani: res.data.lgs_puani,
          yuzdelik_dilim: res.data.yuzdelik_dilim,
          ogrenci_adi: res.data.ogrenci_adi,
          fen: res.data.fen,
          mat: res.data.mat,
          turkce: res.data.turkce,
          ingilizce: res.data.ingilizce,
          inkilap: res.data.inkilap,
          din: res.data.din,
          year: res.data.year,
          city: res.data.city,
          school_type: res.data.school_type
        })

        setTableLoading(false)
      }).catch(() => {

      });
  }

  useEffect(() => {
    SetData();
  }, [location.search]);

  function GetPage() {
    //if(page_number < 1 || page_number > PaginationData.last_page) return `/lgs/get-data/?year=${query.get('year')}&page=${query.get('page')}&q1=${query.get('q1')}&q2=${query.get('q2')}&q3=${query.get('q3')}&q4=${query.get('q4')}&q5=${query.get('q5')}&q6=${query.get('q6')}&o=${query.get('o')}&p=${query.get('p')}&y=${query.get('y')}`;
    var path = `year=${query.get('year')}&q1=${query.get('q1')}&q2=${query.get('q2')}&q3=${query.get('q3')}&q4=${query.get('q4')}&q5=${query.get('q5')}&q6=${query.get('q6')}&o=${query.get('o')}&p=${query.get('p')}&y=${query.get('y')}&city=${query.get('city')}&school_type=${query.get('school_type')}`
    return path

  }

  function GetThreeDigitN(num: string | number) {

    var arr = []
    num = String(num)

    if (num.includes(",") || num.includes(".")) return num;

    if (num.length > 3) {
      var curr_str = ""
      for (let index = 0; index < num.length; index++) {
        const digit = num[index];
        arr.push(digit);
      }

      for (let index = 0; index < 3; index++) {
        const digit = arr[index]
        curr_str += digit;
      }

      curr_str += ",";

      arr = arr.splice(0, 3);

      for (let index = 0; index < arr.length; index++) {
        const digit = arr[index];
        curr_str += digit;
      }

      return curr_str;
    } else {
      return num;
    }

  }

  function ChangeCity(city: string) {

    var path = `/lgs/get-data/?year=${query.get('year')}&q1=${query.get('q1')}&q2=${query.get('q2')}&q3=${query.get('q3')}&q4=${query.get('q4')}&q5=${query.get('q5')}&q6=${query.get('q6')}&o=${query.get('o')}&p=${query.get('p')}&y=${query.get('y')}&city=${city}&school_type=${query.get('school_type')}`

    history.push(path)
  }

  function ChangeSchoolType(schoolType: string) {

    var path = `/lgs/get-data/?year=${query.get('year')}&q1=${query.get('q1')}&q2=${query.get('q2')}&q3=${query.get('q3')}&q4=${query.get('q4')}&q5=${query.get('q5')}&q6=${query.get('q6')}&o=${query.get('o')}&p=${query.get('p')}&y=${query.get('y')}&city=${query.get('city')}&school_type=${schoolType}`

    history.push(path)
  }

  function OpenInfoModal(openState: boolean, id?: number) {
    if (openState) {
      axios.get("https://tercihrobotu.testprojesi.com/api/get-school-details?id=" + id)
        .then(res => {
          setModalState({ ...state, item: res.data, state: true })
        }).catch(err => {
          console.log(err);

        });
    } else {
      setModalState({
        ...state, item: {
          "school_name": "school_details.SchoolName",
          "school_type": "school_details.SchoolType",
          "school_adress": "school_details.SchoolAdress",
          "school_web_adress": "school_details.SchoolWebAdress",
          "school_phone_number": "school_details.Phone",
          "school_fax_number": "school_details.Fax",
          "school_district": "school_details.CityDistrict/school_details.CityName"
        }, state: false
      })
    }

  }

  function GetSelectedSchools() {
    const newArray: any = [];

    for (let index = 0; index < Schools.length; index++) {
      const school = Schools[index];
      var newScArray: any = new Array(10);
      Object.keys(school).forEach(function (key, index) {
        switch (index) {
          case 0:
            newScArray[2] = school[key];
            break;

          case 1:
            newScArray[3] = "-";
            break;

          case 2:
            newScArray[4] = school[key];
            break;

          case 3:
            newScArray[1] = school[key];
            break;

          case 4:
            newScArray[7] = school[key];
            break;

          case 5:
            newScArray[5] = school[key];
            break;

          case 6:
            newScArray[6] = "-";
            break;
          case 7:
            newScArray[8] = "-";
            break;
          case 8:
            newScArray[0] = school[key];
            break;
          default:
            break;
        }

      });

      newArray.push(newScArray);
    }

    return newArray;
  }

  return (
    <>
      <Route path='/lgs'>

        <div className="modal-get shadow-lg ">
          {props.isLoading && <Loader style={{ zIndex: 51 }} />}
          <div className='header' id="t_header">

            <Tooltip title="Ana Sayfa">
              <Link className="home-button" to={"/lgs"}>
                <AiFillHome></AiFillHome>
              </Link>
            </Tooltip>


            LGS TERCİH ROBOTU

            <div className='close-button'>
              <CloseButton style={{ fontSize: 'x-small' }} />
            </div>
          </div>

          <Route exact path={"/lgs"}>
            <div className="main">
              <Box width="100%" height="100%" display="flex" textAlign={"center"} flexDirection={"column"} alignItems="center">
                <img src={biltes_logo} alt="" width={250} />
                <div>
                  Tercih robotuna hoşgeldiniz, <b>başla</b> butonuna basarak hesaplama işlemine geçebilirsin.
                </div>

                <Link to={"/lgs/hesapla"}>
                  <Button style={{ width: 200, backgroundColor: 'rgb(243, 212, 183)' }} className="mt-2">
                    Başla
                  </Button>
                </Link>
              </Box>
            </div>
          </Route>



          <LgsCalculate />

          <Route path={"/lgs/get"}>
            <div className='main'>
              <div className='containe'>
                <div className='row justify-content-center'>
                  <div className='col-1 d-flex flex-column align-items-center' >
                    <span className="netler-input">
                      Türkçe
                    </span>
                    <Input value={state.turkce} type='number' className='mt-2' style={{ width: 50, textAlign: "center", fontSize: "small" }} />
                  </div>


                  <div className='col-1 d-flex flex-column align-items-center' >
                    <span className="netler-input">
                      Tc. Ink.
                    </span>
                    <Input value={state.inkilap} type='number' className='mt-2' style={{ width: 50, textAlign: "center", fontSize: "small" }} />
                  </div>

                  <div className='col-1 d-flex flex-column align-items-center' >
                    <span className="netler-input">
                      Din
                    </span>
                    <Input value={state.din} type='number' className='mt-2' style={{ width: 50, textAlign: "center", fontSize: "small" }} />
                  </div>

                  <div className='col-1 d-flex flex-column align-items-center' >
                    <span className="netler-input">
                      Dil
                    </span>
                    <Input value={state.ingilizce} type='number' className='mt-2' style={{ width: 50, textAlign: "center", fontSize: "small" }} />
                  </div>

                  <div className='col-1 d-flex flex-column align-items-center' >
                    <span className="netler-input">
                      Matematik
                    </span>
                    <Input value={state.mat} type='number' className='mt-2' style={{ width: 50, textAlign: "center", fontSize: "small" }} />
                  </div>

                  <div className='col-1 d-flex flex-column align-items-center' >
                    <span className="netler-input">
                      Fen
                    </span>
                    <Input value={state.fen} type='number' className='mt-2' style={{ width: 50, textAlign: "center", fontSize: "small" }} />
                  </div>

                </div>
              </div>
            </div>

            <div className='explain mt-2 d-flex flex-column'>
              {state.ogrenci_adi != "None" && <span>
                Merhaba, <b>{state.ogrenci_adi}</b>
              </span>}

              <span className='detail'>
                Puanlarınız LGS 2022 verilerine göre hesaplanmıştır. Netlerinize göre kazanabileceğiniz okulların listelerini görmek için lütfen tıklayın
              </span>

              <Link to={'/lgs/get-data/?' + GetPage()}>
                <Button style={{ width: 200, backgroundColor: 'rgb(243, 212, 183)' }} className='mt-4 sonuc-button'>
                  Sonuçları Getir
                </Button>
              </Link>
            </div>
          </Route>

          <Route path={"/lgs/get-data"}>
            <div className='main p-3'>
              <div className='container'>
                <div className='row' style={{ justifyContent: "center", alignItems: "center" }}>

                  <div className='col-md-4 col-xs-12 d-flex flex-column align-items-center' >
                    <span style={{ fontSize: 14 }}>
                      Lgs Puanı
                    </span>
                    <Input value={GetThreeDigitN(state.lgs_puani)} className='mt-2' style={{ textAlign: "center", fontSize: "small" }} />
                  </div>




                  <div className='col-md-4 col-xs-12 d-flex flex-column align-items-center' >
                    <span style={{ fontSize: 14 }}>
                      Yüzdelik Dilim
                    </span>
                    <Input value={state.yuzdelik_dilim} className='mt-2' style={{ textAlign: "center", fontSize: "small" }} />
                  </div>


                </div>
              </div>
            </div>


            <div className='sonuc-listesi p-2'>
              <Box sx={{ display: { xs: 'none', md: 'flex' } }} width="100%">
                <span className='p-2 d-flex align-center' style={{ width: "100%" }}>
                  Sonuç Listesi - 2022

                  <FormGroup style={{ marginLeft: "auto" }}>
                    <Button disabled={Schools.length == 0} onClick={() => MakeExport(
                      "lgs_sonuclar",
                      ["Okul Türü", "Okul", "İl", "Pansiyon", "Dil", "Puan", "Sıralama", "Y.Dilim", "Kontenjan"],
                      GetSelectedSchools(),
                      state.lgs_puani,
                      state.yuzdelik_dilim,
                      state.ogrenci_adi
                    )} title="PDF Çıktısı Al" outline={true}>
                      <AiFillFilePdf />
                    </Button>
                  </FormGroup>

                  <FormGroup style={{ marginLeft: 5 }}>
                    <Input type="select" name="lgs_city_select" id="lgs_city_select" onChange={(e) => ChangeCity(e.target.value)} value={state.city} >
                      <option value={""} disabled>Şehir</option>
                      <option value="İstanbul">İstanbul</option>
                      <option value="Ankara">Ankara</option>
                      <option value="İzmir">İzmir</option>
                      <option value="Adana">Adana</option>
                      <option value="Adıyaman">Adıyaman</option>
                      <option value="Afyonkarahisar">Afyonkarahisar</option>
                      <option value="Ağrı">Ağrı</option>
                      <option value="Aksaray">Aksaray</option>
                      <option value="Amasya">Amasya</option>
                      <option value="Antalya">Antalya</option>
                      <option value="Ardahan">Ardahan</option>
                      <option value="Artvin">Artvin</option>
                      <option value="Aydın">Aydın</option>
                      <option value="Balıkesir">Balıkesir</option>
                      <option value="Bartın">Bartın</option>
                      <option value="Batman">Batman</option>
                      <option value="Bayburt">Bayburt</option>
                      <option value="Bilecik">Bilecik</option>
                      <option value="Bingöl">Bingöl</option>
                      <option value="Bitlis">Bitlis</option>
                      <option value="Bolu">Bolu</option>
                      <option value="Burdur">Burdur</option>
                      <option value="Bursa">Bursa</option>
                      <option value="Çanakkale">Çanakkale</option>
                      <option value="Çankırı">Çankırı</option>
                      <option value="Çorum">Çorum</option>
                      <option value="Denizli">Denizli</option>
                      <option value="Diyarbakır">Diyarbakır</option>
                      <option value="Düzce">Düzce</option>
                      <option value="Edirne">Edirne</option>
                      <option value="Elazığ">Elazığ</option>
                      <option value="Erzincan">Erzincan</option>
                      <option value="Erzurum">Erzurum</option>
                      <option value="Eskişehir">Eskişehir</option>
                      <option value="Gaziantep">Gaziantep</option>
                      <option value="Giresun">Giresun</option>
                      <option value="Gümüşhane">Gümüşhane</option>
                      <option value="Hakkâri">Hakkâri</option>
                      <option value="Hatay">Hatay</option>
                      <option value="Iğdır">Iğdır</option>
                      <option value="Isparta">Isparta</option>
                      <option value="Kahramanmaraş">Kahramanmaraş</option>
                      <option value="Karabük">Karabük</option>
                      <option value="Karaman">Karaman</option>
                      <option value="Kars">Kars</option>
                      <option value="Kastamonu">Kastamonu</option>
                      <option value="Kayseri">Kayseri</option>
                      <option value="Kırıkkale">Kırıkkale</option>
                      <option value="Kırklareli">Kırklareli</option>
                      <option value="Kırşehir">Kırşehir</option>
                      <option value="Kilis">Kilis</option>
                      <option value="Kocaeli">Kocaeli</option>
                      <option value="Konya">Konya</option>
                      <option value="Kütahya">Kütahya</option>
                      <option value="Malatya">Malatya</option>
                      <option value="Manisa">Manisa</option>
                      <option value="Mardin">Mardin</option>
                      <option value="Mersin">Mersin</option>
                      <option value="Muğla">Muğla</option>
                      <option value="Muş">Muş</option>
                      <option value="Nevşehir">Nevşehir</option>
                      <option value="Niğde">Niğde</option>
                      <option value="Ordu">Ordu</option>
                      <option value="Osmaniye">Osmaniye</option>
                      <option value="Rize">Rize</option>
                      <option value="Sakarya">Sakarya</option>
                      <option value="Samsun">Samsun</option>
                      <option value="Siirt">Siirt</option>
                      <option value="Sinop">Sinop</option>
                      <option value="Sivas">Sivas</option>
                      <option value="Şırnak">Şırnak</option>
                      <option value="Tekirdağ">Tekirdağ</option>
                      <option value="Tokat">Tokat</option>
                      <option value="Trabzon">Trabzon</option>
                      <option value="Tunceli">Tunceli</option>
                      <option value="Şanlıurfa">Şanlıurfa</option>
                      <option value="Uşak">Uşak</option>
                      <option value="Van">Van</option>
                      <option value="Yalova">Yalova</option>
                      <option value="Yozgat">Yozgat</option>
                      <option value="Zonguldak">Zonguldak</option>
                    </Input>
                  </FormGroup>

                  <FormGroup style={{ marginLeft: 5 }}>
                    <Input type="select" name="lgs_school_type" id="lgs_school_type" onChange={(e) => ChangeSchoolType(e.target.value)} value={state.school_type}>
                      <option value="" disabled>Okul Türü</option>
                      <option value="hepsi">Hepsi</option>
                      <option value="anadolu_lisesi">Anadolu Lisesi</option>
                      <option value="fen_lisesi">Fen Lisesi</option>
                      <option value="anadolu_meslek_programı">Anadolu Meslek Programı</option>
                      <option value="anadolu_teknik_programı">Anadolu Teknik Programı</option>
                      <option value="anadolu_imamhatip_lisesi">Anadolu İmam Hatip Lisesi</option>
                    </Input>
                  </FormGroup>
                </span>
              </Box>

              <Box sx={{ display: { xs: 'flex', md: 'none' } }} width="100%">
                <span className='p-2 d-flex align-center' style={{ width: "100%", flexDirection: "column", alignItems: "center" }}>
                  Sonuç Listesi - 2022

                  <FormGroup className="w-100 mt-2">
                    <Input type="select" name="lgs_city_select" id="lgs_city_select" onChange={(e) => ChangeCity(e.target.value)} value={state.city} >
                      <option value={""} disabled>Şehir</option>
                      <option value="İstanbul">İstanbul</option>
                      <option value="Ankara">Ankara</option>
                      <option value="İzmir">İzmir</option>
                      <option value="Adana">Adana</option>
                      <option value="Adıyaman">Adıyaman</option>
                      <option value="Afyonkarahisar">Afyonkarahisar</option>
                      <option value="Ağrı">Ağrı</option>
                      <option value="Aksaray">Aksaray</option>
                      <option value="Amasya">Amasya</option>
                      <option value="Antalya">Antalya</option>
                      <option value="Ardahan">Ardahan</option>
                      <option value="Artvin">Artvin</option>
                      <option value="Aydın">Aydın</option>
                      <option value="Balıkesir">Balıkesir</option>
                      <option value="Bartın">Bartın</option>
                      <option value="Batman">Batman</option>
                      <option value="Bayburt">Bayburt</option>
                      <option value="Bilecik">Bilecik</option>
                      <option value="Bingöl">Bingöl</option>
                      <option value="Bitlis">Bitlis</option>
                      <option value="Bolu">Bolu</option>
                      <option value="Burdur">Burdur</option>
                      <option value="Bursa">Bursa</option>
                      <option value="Çanakkale">Çanakkale</option>
                      <option value="Çankırı">Çankırı</option>
                      <option value="Çorum">Çorum</option>
                      <option value="Denizli">Denizli</option>
                      <option value="Diyarbakır">Diyarbakır</option>
                      <option value="Düzce">Düzce</option>
                      <option value="Edirne">Edirne</option>
                      <option value="Elazığ">Elazığ</option>
                      <option value="Erzincan">Erzincan</option>
                      <option value="Erzurum">Erzurum</option>
                      <option value="Eskişehir">Eskişehir</option>
                      <option value="Gaziantep">Gaziantep</option>
                      <option value="Giresun">Giresun</option>
                      <option value="Gümüşhane">Gümüşhane</option>
                      <option value="Hakkâri">Hakkâri</option>
                      <option value="Hatay">Hatay</option>
                      <option value="Iğdır">Iğdır</option>
                      <option value="Isparta">Isparta</option>
                      <option value="Kahramanmaraş">Kahramanmaraş</option>
                      <option value="Karabük">Karabük</option>
                      <option value="Karaman">Karaman</option>
                      <option value="Kars">Kars</option>
                      <option value="Kastamonu">Kastamonu</option>
                      <option value="Kayseri">Kayseri</option>
                      <option value="Kırıkkale">Kırıkkale</option>
                      <option value="Kırklareli">Kırklareli</option>
                      <option value="Kırşehir">Kırşehir</option>
                      <option value="Kilis">Kilis</option>
                      <option value="Kocaeli">Kocaeli</option>
                      <option value="Konya">Konya</option>
                      <option value="Kütahya">Kütahya</option>
                      <option value="Malatya">Malatya</option>
                      <option value="Manisa">Manisa</option>
                      <option value="Mardin">Mardin</option>
                      <option value="Mersin">Mersin</option>
                      <option value="Muğla">Muğla</option>
                      <option value="Muş">Muş</option>
                      <option value="Nevşehir">Nevşehir</option>
                      <option value="Niğde">Niğde</option>
                      <option value="Ordu">Ordu</option>
                      <option value="Osmaniye">Osmaniye</option>
                      <option value="Rize">Rize</option>
                      <option value="Sakarya">Sakarya</option>
                      <option value="Samsun">Samsun</option>
                      <option value="Siirt">Siirt</option>
                      <option value="Sinop">Sinop</option>
                      <option value="Sivas">Sivas</option>
                      <option value="Şırnak">Şırnak</option>
                      <option value="Tekirdağ">Tekirdağ</option>
                      <option value="Tokat">Tokat</option>
                      <option value="Trabzon">Trabzon</option>
                      <option value="Tunceli">Tunceli</option>
                      <option value="Şanlıurfa">Şanlıurfa</option>
                      <option value="Uşak">Uşak</option>
                      <option value="Van">Van</option>
                      <option value="Yalova">Yalova</option>
                      <option value="Yozgat">Yozgat</option>
                      <option value="Zonguldak">Zonguldak</option>
                    </Input>
                  </FormGroup>

                  <FormGroup className="w-100">
                    <Input type="select" name="lgs_school_type" id="lgs_school_type" onChange={(e) => ChangeSchoolType(e.target.value)} value={state.school_type}>
                      <option value="" disabled>Okul Türü</option>
                      <option value="hepsi">Hepsi</option>
                      <option value="anadolu_lisesi">Anadolu Lisesi</option>
                      <option value="fen_lisesi">Fen Lisesi</option>
                      <option value="anadolu_meslek_programı">Anadolu Meslek Programı</option>
                      <option value="anadolu_teknik_programı">Anadolu Teknik Programı</option>
                      <option value="anadolu_imamhatip_lisesi">Anadolu İmam Hatip Lisesi</option>
                    </Input>
                  </FormGroup>
                </span>
              </Box>

              <Box sx={{ display: { xs: "none", md: "block" } }}>
                <Table striped hover style={{ overflow: "hidden", minHeight: (tableLoading == true || selectedSchools.length == 0) ? 300 : "auto", position: "relative" }}>
                  {tableLoading && <Loader />}
                  {(tableLoading == false && selectedSchools.length == 0) &&
                    <div
                      className="loader"
                    >
                      Herhangi bir okul bulunamadı
                    </div>}
                  <thead>
                    <tr>
                      <th>
                        #
                      </th>
                      <th>
                        Okul Türü
                      </th>
                      <th>
                        Okul
                      </th>
                      <th>
                        İl
                      </th>

                      <th>
                        Pansiyon
                      </th>

                      <th>
                        Dil
                      </th>

                      <th>
                        Puan
                      </th>

                      <th>
                        Sıralama
                      </th>

                      <th>
                        Y.Dilim
                      </th>

                      <th>
                        Kontenjan
                      </th>

                      <th>
                        Okul Detayı
                      </th>
                    </tr>
                  </thead>

                  <tbody style={{}}>
                    {selectedSchools.map((item: any, index) => {

                      return (
                        <tr style={{ cursor: "pointer" }} >
                          <th>
                            {index + 1}
                          </th>

                          <th>
                            {item.SchoolType}
                          </th>

                          <th title={item.SchoolName}>
                            <div className="cut-text">
                              {item.SchoolName}
                            </div>
                          </th>

                          <th>
                            {item.City}
                          </th>

                          <th>
                            -
                          </th>

                          <th>
                            {item.SchoolLanguage}
                          </th>

                          <th>
                            {item.SchoolScore}
                          </th>

                          <th>
                            -
                          </th>

                          <th>
                            {item.SchoolPercent}
                          </th>

                          <th>
                            -
                          </th>

                          <th>
                            {item.SchoolDetailsID != 0 ?
                              <>
                                <Button style={{ margin: 0, padding: 0 }} color="link" size="sm" onClick={() => OpenInfoModal(true, item.SchoolDetailsID)}>
                                  Detay
                                </Button>
                              </>
                              :
                              <>
                                Yok
                              </>}
                          </th>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </Box>

              <Box sx={{ display: { xs: "block", md: "none" } }}>
                <Table striped hover style={{ overflow: "hidden", minHeight: (tableLoading == true || selectedSchools.length == 0) ? 300 : "auto", position: "relative" }}>
                  {tableLoading && <Loader />}
                  {(tableLoading == false && selectedSchools.length == 0) &&
                    <div
                      className="loader"
                    >
                      Herhangi bir okul bulunamadı
                    </div>}
                  <thead>
                    <tr>


                      <th>
                        Okul Türü
                      </th>
                      <th>
                        Okul
                      </th>

                      <th>
                        Puan ve Y.Dilim
                      </th>


                      <th>
                        Okul Detayı
                      </th>
                    </tr>
                  </thead>

                  <tbody style={{}}>
                    {selectedSchools.map((item: any, index) => {

                      return (
                        <tr style={{ cursor: "pointer" }} >


                          <th>
                            {item.SchoolType}
                          </th>

                          <th title={item.SchoolName}>
                            <div className="cut-text">
                              {item.SchoolName}
                            </div>
                            <div>
                              İl: {item.City}
                            </div>
                            <div>
                              Dil: {item.SchoolLanguage}
                            </div>
                          </th>


                          <th>
                            <div>
                              Puan: {item.SchoolScore}
                            </div>
                            <div>
                              Y. Dilim: {item.SchoolPercent}
                            </div>
                          </th>

                          <th>
                            {item.SchoolDetailsID != 0 ?
                              <>
                                <Button style={{ margin: 0, padding: 0 }} color="link" size="sm" onClick={() => OpenInfoModal(true, item.SchoolDetailsID)}>
                                  Detay
                                </Button>
                              </>
                              :
                              <>
                                Yok
                              </>}
                          </th>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </Box>

              {(!tableLoading) && <PaginatedItems itemsPerPage={8} items={Schools} changeCurrentItems={(items: any) => setSelectedSchools(items)} />}



            </div>
          </Route>
        </div>
      </Route>

      <Modal isOpen={modalState.state} toggle={() => OpenInfoModal(false)}>
        <ModalHeader>
          Okul Detayları
        </ModalHeader>
        <ModalBody>
          <div className="row mt-3">
            <div className="col-12" style={{ fontSize: "1rem", fontWeight: "semibold", color: "rgb(51, 51, 51, 0.7)" }}>
              Kurum Adı : <span style={{ fontSize: "1rem", fontWeight: "inherit", color: "black" }}>{modalState.item!['school_name']}</span>
            </div>

            <div className="col-12" style={{ fontSize: "1rem", fontWeight: "semibold", color: "rgb(51, 51, 51, 0.7)" }}>
              Kurum Türü : <span style={{ fontSize: "1rem", fontWeight: "inherit", color: "black" }}>{modalState.item!['school_type']}</span>
            </div>


            <div className="col-12" style={{ fontSize: "1rem", fontWeight: "semibold", color: "rgb(51, 51, 51, 0.7)" }}>
              İnternet Sitesi :
              <span style={{ marginLeft: 5, fontSize: "1rem", fontWeight: "inherit", color: "black" }}>
                <a href={`https://` + modalState.item!['school_web_adress']} target={"_blank"}>
                  Gitmek için tıklayın
                </a>
              </span>
            </div>

            <div className="col-12" style={{ fontSize: "1rem", fontWeight: "semibold", color: "rgb(51, 51, 51, 0.7)" }}>
              Telefon : <span style={{ fontSize: "1rem", fontWeight: "inherit", color: "black" }}>{modalState.item!['school_phone_number']}</span>
            </div>

            <div className="col-12" style={{ fontSize: "1rem", fontWeight: "semibold", color: "rgb(51, 51, 51, 0.7)" }}>
              Faks : <span style={{ fontSize: "1rem", fontWeight: "inherit", color: "black" }}>{modalState.item!['school_fax_number']}</span>
            </div>


            <div className="col-12" style={{ fontSize: "1rem", fontWeight: "semibold", color: "rgb(51, 51, 51, 0.7)" }}>
              İlçe/İl : <span style={{ fontSize: "1rem", fontWeight: "inherit", color: "black" }}>{modalState.item!['school_district']}</span>
            </div>


            <div className="col-12" style={{ fontSize: "1rem", fontWeight: "semibold", color: "rgb(51, 51, 51, 0.7)" }}>
              Adres : <span style={{ fontSize: "1rem", fontWeight: "inherit", color: "black" }}>{modalState.item!['school_adress']}</span>
            </div>
          </div>
        </ModalBody>
        <ModalFooter >
          <Button onClick={() => OpenInfoModal(false)}>Kapat</Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default Lgs;

/*
<Route exact path={"/lgs"}>
              <div className="main">
                <div className="test-page">
                  <div className="test-page-text">
                    LGS tercih robotuna hoşgeldiniz, test için aşşağıdaki butona 
                    <Link style={{ marginLeft: 5 }} to={"/lgs/get/?year=2021&q1=13&q2=10&q3=7.33&q4=8.67&q5=15.33&q6=14.67&o=Eray%20Aydın&p=423.453&y=6.18&city=İstanbul&school_type=hepsi"}>
                 
                  tıklayın
                  </Link>
                  .
                  </div>

                  
                </div>
              </div>
              </Route>
*/