import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useHistory } from "react-router";

function PaginatedItems({ itemsPerPage,  items, changeCurrentItems }:any) {
  
    // We start with an empty list of items.
    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);

    useEffect(() => {
        // Fetch items from another resources.
        const endOffset = itemOffset + itemsPerPage;
        console.log(`Loading items from ${itemOffset} to ${endOffset}`);
        setCurrentItems(items.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(items.length / itemsPerPage));
    }, [itemOffset, itemsPerPage, items]);

    useEffect(() => {
        changeCurrentItems(currentItems)
    }, [currentItems])

    // Invoke when user click to request another page.
    const handlePageClick = (event:any) => {
        const newOffset = (event.selected * itemsPerPage) % items.length;
        console.log(
        `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
    };

  return (
    <>
      {pageCount > 0 && 
      <ReactPaginate
      containerClassName="pagination justify-content-center"
      pageClassName="page-item"
      pageLinkClassName="page-link-s"
      previousClassName="page-item"
      previousLinkClassName="page-link"
      nextClassName="page-item"
      nextLinkClassName="page-link"
      activeClassName="active"
      breakLabel="..."
      nextLabel="ileri >"
      onPageChange={handlePageClick}
      pageRangeDisplayed={itemsPerPage}
      pageCount={pageCount}
      previousLabel="<  geri"
    
      
      
    />}
    </>
  );
}

export default PaginatedItems;