import { Box, Grid, styled } from "@mui/material";
import { useEffect, useState } from "react";
import { Route, Switch, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import LgsCalculate from "./Components/LgsCalculate";
import Lgs from "./Components/LgsEngine";
import Yks from "./Components/YksEngine";
import biltes_logo from "./Assets/biltes_logo.jpeg";

const Root = styled('div')(({ theme }:any) => ({
  'padding-top': 16,
  'padding-bottom': 16,
  [theme.breakpoints.up('xl')]: {
    width: "100%",
    height: "100%",
    'display': "flex",
    'align-items': "center",
    'justify-content': "center"
  },
}));

function App() {
  const [isLoading, setLoading] = useState(false);
  const [hostname, setHostName] = useState(CheckHostname())
  const location = useLocation();


  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1100);
  }, [location.pathname]);

  useEffect(() => {
    
  }, []);

  function CheckHostname() {
    var hostname = window.location.hostname

    if(hostname.includes('biltesdigital')) return true
    if(hostname.includes('tercihrobotu.testprojesi')) return true
    if(hostname.includes('localhost')) return true
    return false
  }

  
  return (
    <>
    <Root>
      {hostname == false ? (
        <div className="modal-get shadow-lg d-flex align-items-center justify-content-center flex-column">
          <span>
            <img 
            width={200}
            src={biltes_logo} alt={biltes_logo} />
          </span>
          <span style={{ marginTop: 20 }}>
          Bu yazılımın tüm hakları <a target={"_blank"} href="https://biltesdigital.com">biltesdigital.com</a>'a aittir. İletişim için lütfen <a href="mailto:iletisim@biltesdigital.com">iletisim@biltesdigital.com</a> adresine yazınız.
          </span>
        </div>
      ) : (
        <>
        <Switch>
          <Route exact path={"/"}>
            <div className="modal-get shadow-lg d-flex align-items-center justify-content-center flex-column">
              <div className="main">
                <Box width="100%" height="100%" display="flex" textAlign={"center"} flexDirection={"column"} alignItems="center">
                  <img src={biltes_logo} alt="" width={250} />
                  <div>
                 Tercih robotuna hoşgeldiniz, aşağıdaki butonlara basarak <b>hesaplama</b> işlemine geçebilirsin.
                  </div>

                  <Grid container spacing={4} justifyContent={"center"}>
                    <Grid item xl={3} sm={12} container justifyContent={"center"}>
                      <Link to={"/yks/hesapla"}>
                      <Button style={{ width: 200, backgroundColor: 'rgb(243, 212, 183)', textShadow: "0px 1px 3px black" }} className="mt-2">
                        YKS Tercih Robotu
                      </Button>
                      </Link>
                    </Grid>

                    <Grid item xl={3} sm={12} container justifyContent={"center"}>
                      <Link to={"/lgs/hesapla"}>
                      <Button style={{ width: 200, backgroundColor: 'rgb(243, 212, 183)', textShadow: "0px 1px 3px black" }} className="mt-2">
                        LGS Tercih Robotu
                      </Button>
                      </Link>
                    </Grid>
                  </Grid>
                </Box>
              </div>
            </div>
          </Route>
        </Switch>

        <Switch>
          <Yks isLoading={isLoading} />
        </Switch>

        <Switch>
          <Lgs isLoading={isLoading} />
        </Switch>
        </>
      )}
    </Root>
    </>
  );
}

export default App;
